import { ChangeDetectionStrategy, Component } from "@angular/core";
import { FormControl } from "@angular/forms";
import {
    IncidentErrorType,
    IncidentSharedDataState,
    IncidentStatus,
    Task,
    TaskCreatorPilotFormValues,
} from "@dtm-frontend/search-and-help-shared-lib/incident";
import { AnimationUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { combineLatestWith, map, startWith } from "rxjs";
import { PanelType } from "../../models/incident.models";
import { IncidentActions } from "../../state/incident.actions";
import { IncidentState } from "../../state/incident.state";

const PANEL_VIEWS: PanelType[] = [PanelType.Tasks, PanelType.TaskCreator, PanelType.TaskPreview];

@UntilDestroy()
@Component({
    selector: "sah-mobile-lib-tasks-panel",
    templateUrl: "tasks-panel.component.html",
    styleUrls: ["../../../shared/styles/incident.scss", "tasks-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInSideOffScreenDown()],
})
export class TasksPanelComponent {
    protected readonly PanelType = PanelType;
    protected readonly IncidentStatus = IncidentStatus;

    protected readonly showOnlyPilotTasksControl = new FormControl<boolean>(false, { nonNullable: true });

    protected readonly isProcessing$ = this.store.select(IncidentState.isProcessing);
    protected readonly panelType$ = this.store.select(IncidentState.panelOpenState);
    protected readonly isPilotTaskApprovalRequired$ = this.store.select(IncidentSharedDataState.isPilotTaskApprovalRequired);
    protected readonly isExpanded$ = this.panelType$.pipe(map((state) => PANEL_VIEWS.some((panelView) => state[panelView])));
    protected readonly tasks$ = this.store.select(IncidentSharedDataState.tasks).pipe(
        combineLatestWith(this.showOnlyPilotTasksControl.valueChanges.pipe(startWith(this.showOnlyPilotTasksControl.value))),
        map(([tasks, shouldShowOnlyPilotTasks]) => {
            if (!shouldShowOnlyPilotTasks) {
                return tasks;
            }

            return tasks.filter((task) => task.isMine);
        })
    );
    protected readonly incidentStatus$ = this.store.select(IncidentSharedDataState.incidentStatus);
    protected readonly previewedTask$ = this.store.select(IncidentState.previewedTask);

    constructor(
        private readonly store: Store,
        private readonly translocoService: TranslocoService,
        private readonly toastrService: ToastrService
    ) {}

    protected closePanel(): void {
        this.store.dispatch(IncidentActions.ClosePanel);
    }

    protected goToPanel(panelType: PanelType): void {
        this.store.dispatch(new IncidentActions.OpenPanel(panelType));
    }

    protected createTaskWithArea(form: TaskCreatorPilotFormValues): void {
        this.store
            .dispatch(new IncidentActions.CreateTaskWithArea(form))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const createdTaskError = this.store.selectSnapshot(IncidentState.createdTaskError);
                if (createdTaskError) {
                    this.toastrService.error(this.translocoService.translate("sahMobileLibIncident.taskCreator.taskCreationUnknownError"));

                    return;
                }

                const createdAreaError = this.store.selectSnapshot(IncidentState.createdAreaError);
                if (createdAreaError) {
                    this.toastrService.error(
                        this.translocoService.translate(
                            createdAreaError.type === IncidentErrorType.AreaOutOfIncidentBoundary
                                ? "sahMobileLibIncident.taskCreator.areaOutOfIncidentBoundaryError"
                                : "sahMobileLibIncident.taskCreator.areaCreationUnknownError"
                        )
                    );

                    return;
                }

                const areaAssignError = this.store.selectSnapshot(IncidentState.areaAssignError);
                if (areaAssignError) {
                    this.toastrService.error(this.translocoService.translate("sahMobileLibIncident.taskCreator.areaAssignUnknownError"));

                    return;
                }

                const isAcceptanceRequired = this.store.selectSnapshot(IncidentSharedDataState.isPilotTaskApprovalRequired);
                this.toastrService.success(
                    this.translocoService.translate(
                        isAcceptanceRequired
                            ? "sahMobileLibIncident.taskCreator.taskWithAreaRequiredAcceptanceCreationSuccessMessage"
                            : "sahMobileLibIncident.taskCreator.taskWithAreaCreationSuccessMessage"
                    )
                );
                this.goToPanel(PanelType.Tasks);
            });
    }

    protected cancelTaskCreation(): void {
        this.store.dispatch(IncidentActions.RemoveTaskCreationData);
    }

    protected previewTask(task: Task): void {
        this.store.dispatch(new IncidentActions.OpenTaskPreview(task));
    }
}
