import { Injectable } from "@angular/core";
import {
    ACTION_MARKER_TOOLS,
    IncidentError,
    IncidentSharedDataState,
    MapToolName,
    Task,
    TaskError,
} from "@dtm-frontend/search-and-help-shared-lib/incident";
import { Action, Selector, State, StateContext, Store } from "@ngxs/store";
import { catchError, EMPTY, finalize, iif, of, switchMap, tap } from "rxjs";
import { PanelOpenState, PanelType } from "../models/incident.models";
import { IncidentApiService } from "../services/incident-api.service";
import { IncidentActions } from "./incident.actions";

interface IncidentStateModel {
    isProcessing: boolean;

    selectedMapToolName: MapToolName;
    isSelectionEnabled: boolean;
    isActionMarkerCreationEnabled: boolean;

    createdAreaId: string | undefined;
    createdAreaError: IncidentError | undefined;
    createdTaskId: string | undefined;
    createdTaskError: TaskError | undefined;
    areaAssignError: IncidentError | undefined;

    previewedTask: Task | undefined;

    panelOpenState: PanelOpenState;
}

const DEFAULT_STATE: IncidentStateModel = {
    isProcessing: false,

    selectedMapToolName: MapToolName.Cursor,
    isSelectionEnabled: true,
    isActionMarkerCreationEnabled: false,

    createdAreaId: undefined,
    createdAreaError: undefined,
    createdTaskId: undefined,
    createdTaskError: undefined,
    areaAssignError: undefined,

    previewedTask: undefined,

    panelOpenState: {
        [PanelType.ActionMarkers]: false,
        [PanelType.ActionMarkerCreator]: false,
        [PanelType.ActionMarkerEdit]: false,
        [PanelType.ActionMarkerIconChange]: false,
        [PanelType.ActionMarkerPreview]: false,
        [PanelType.Areas]: false,
        [PanelType.Chat]: false,
        [PanelType.Tasks]: false,
        [PanelType.TaskCreator]: false,
        [PanelType.TaskPreview]: false,
    },
};

@State<IncidentStateModel>({
    name: "incident",
    defaults: DEFAULT_STATE,
})
@Injectable()
export class IncidentState {
    @Selector()
    public static isProcessing(state: IncidentStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static selectedMapToolName(state: IncidentStateModel): MapToolName {
        return state.selectedMapToolName;
    }

    @Selector()
    public static isSelectionEnabled(state: IncidentStateModel): boolean {
        return state.isSelectionEnabled;
    }

    @Selector()
    public static isActionMarkerCreationEnabled(state: IncidentStateModel): boolean {
        return state.isActionMarkerCreationEnabled;
    }

    @Selector()
    public static createdAreaError(state: IncidentStateModel): IncidentError | undefined {
        return state.createdAreaError;
    }

    @Selector()
    public static createdTaskError(state: IncidentStateModel): TaskError | undefined {
        return state.createdTaskError;
    }

    @Selector()
    public static areaAssignError(state: IncidentStateModel): IncidentError | undefined {
        return state.areaAssignError;
    }

    @Selector()
    public static previewedTask(state: IncidentStateModel): Task | undefined {
        return state.previewedTask;
    }

    @Selector()
    public static panelOpenState(state: IncidentStateModel): PanelOpenState {
        return state.panelOpenState;
    }

    constructor(private readonly apiService: IncidentApiService, private readonly store: Store) {}

    @Action(IncidentActions.ResetState)
    public resetState(context: StateContext<IncidentStateModel>) {
        context.patchState(DEFAULT_STATE);
    }

    @Action(IncidentActions.SelectMapTool)
    public selectMapTool(context: StateContext<IncidentStateModel>, { mapToolName }: IncidentActions.SelectMapTool) {
        context.patchState({
            selectedMapToolName: mapToolName,
            isSelectionEnabled: mapToolName === MapToolName.Cursor,
            isActionMarkerCreationEnabled: ACTION_MARKER_TOOLS.some((tool) => tool.name === mapToolName),
        });
    }

    @Action(IncidentActions.OpenPanel)
    public openPanel(context: StateContext<IncidentStateModel>, action: IncidentActions.OpenPanel) {
        const clearedPanelOpenState = Object.keys(PanelType).reduce((result, key) => ({ ...result, [key]: false }), {}) as PanelOpenState;

        context.patchState({ panelOpenState: { ...clearedPanelOpenState, [action.panelViewType]: true } });
    }

    @Action(IncidentActions.ClosePanel)
    public closePanel(context: StateContext<IncidentStateModel>) {
        const clearedPanelOpenState = Object.keys(PanelType).reduce((result, key) => ({ ...result, [key]: false }), {}) as PanelOpenState;

        context.patchState({ panelOpenState: clearedPanelOpenState });
    }

    @Action(IncidentActions.CreateTaskWithArea, { cancelUncompleted: true })
    public createTaskWithArea(context: StateContext<IncidentStateModel>, { form }: IncidentActions.CreateTaskWithArea) {
        context.patchState({ isProcessing: true, createdTaskError: undefined, createdAreaError: undefined, areaAssignError: undefined });
        const incidentId = this.store.selectSnapshot(IncidentSharedDataState.incidentId);
        const teamId = this.store.selectSnapshot(IncidentSharedDataState.team)?.id;

        if (!incidentId || !teamId) {
            return;
        }

        const { createdAreaId, createdTaskId } = context.getState();

        // TODO: Temporary solution, will be replaced with single endpoint in SAH-344
        return iif(
            () => !!createdTaskId,
            of([]),
            this.apiService.createTask(incidentId, teamId, form.data).pipe(
                tap((task) => context.patchState({ createdTaskId: task.id })),
                catchError((error) => {
                    context.patchState({ createdTaskError: error });

                    return EMPTY;
                })
            )
        ).pipe(
            switchMap(() =>
                iif(
                    () => !!createdAreaId,
                    of([]),
                    this.apiService.createArea(incidentId, form).pipe(
                        tap((area) => context.patchState({ createdAreaId: area.id })),
                        catchError((error) => {
                            context.patchState({ createdAreaError: error });

                            return EMPTY;
                        })
                    )
                )
            ),
            switchMap(() => {
                const { createdAreaId: areaId, createdTaskId: taskId } = context.getState();

                if (areaId && taskId) {
                    return this.apiService.assignAreaToTask(incidentId, taskId, areaId).pipe(
                        catchError((error) => {
                            context.patchState({ areaAssignError: error });

                            return EMPTY;
                        })
                    );
                }

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(IncidentActions.RemoveTaskCreationData)
    public removeTaskCreationData(context: StateContext<IncidentStateModel>) {
        context.patchState({
            createdAreaId: undefined,
            createdTaskId: undefined,
        });
    }

    @Action(IncidentActions.OpenTaskPreview)
    public openTaskPreview(context: StateContext<IncidentStateModel>, { task }: IncidentActions.OpenTaskPreview) {
        context.dispatch(new IncidentActions.OpenPanel(PanelType.TaskPreview));
        context.patchState({ previewedTask: task });
    }
}
