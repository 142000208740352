import { IMessage } from "@stomp/rx-stomp";
import { Circle, GeoJSON, Polygon, Polyline } from "leaflet";
import { ActionMarkerData, ACTION_MARKER_TOOLS, HandDrawingPolyline, MapArea, MapToolName } from "../models/incident-map.models";
import { IncidentEvent, IncidentEventMessage, IncidentMessage, IncidentStatus, OperationalSituation } from "../models/incident.models";
import { Task, TaskStatus } from "../models/task.models";
import { TeamStatus } from "../models/team.models";

export interface AreaEntity {
    id: string;
    geometry: GeoJSON.Polygon;
    center: GeoJSON.Point;
    radius: number;
    taskIds: string[];
    version: number;
    name: string;
}

export interface HandDrawingEntityResponse {
    geometry: GeoJSON.LineString;
    id: string;
    version: number;
}

export interface CreateOrUpdateActionMarkerResponseBody {
    id: string;
    authorId: string;
    description: string;
    location: {
        latitude: number;
        longitude: number;
    };
    iconId: ActionMarkerIcon;
    photoId: string;
    version: number;
}

interface CreateOrUpdateTeamResponseBody {
    id: string;
    name: string;
    version: number;
    teamId: string;
}

interface CreateOrUpdateTaskResponseBody {
    id: string;
    name: string;
    status: TaskStatus;
    areaId: string;
    altitudeRange: {
        floor: number;
        ceiling: number;
    };
    timeRange: {
        start: Date;
        stop: Date;
    };
    assignedTeam: {
        id: string;
        teamId: string;
        name: string;
        status: TeamStatus;
        version: number;
    };
    airborne: boolean;
    version: number;
    notes: string;
    rejectionReason: string;
    authorId: string;
}

export interface GetOperationSituationResponseBody {
    status: IncidentStatus;
    incidentArea: AreaEntity;
    tasks: CreateOrUpdateTaskResponseBody[];
    internalAreas: AreaEntity[];
    doodles: HandDrawingEntityResponse[];
    markers: CreateOrUpdateActionMarkerResponseBody[];
    teams: CreateOrUpdateTeamResponseBody[];
    team: CreateOrUpdateTeamResponseBody;
    pilotTaskApprovalRequired: boolean;
    comments: IncidentMessageEntity[];
}

export interface CreateOrUpdateActionMarkerRequestPayload {
    description?: string;
    location?: {
        latitude: number;
        longitude: number;
    };
    iconId?: ActionMarkerIcon;
}

export enum ActionMarkerIcon {
    ActionMarkerRepresentative = "REPRESENTATIVE",
    ActionMarkerGroup = "GROUP",
    ActionMarkerHelp = "HELP",
    ActionMarkerAlert = "ALERT",
    ActionMarkerFire = "FIRE",
    ActionMarkerWater = "WATER",
    ActionMarkerCamera = "CAMERA",
    ActionMarkerFlag = "FLAG",
    ActionMarkerPlane = "PLANE",
    ActionMarkerHelicopter = "HELICOPTER",
    ActionMarkerSailboat = "SAILBOAT",
    ActionMarkerCar = "CAR",
    ActionMarkerA = "TAG_A",
    ActionMarkerB = "TAG_B",
    ActionMarkerC = "TAG_C",
    ActionMarkerD = "TAG_D",
    ActionMarkerE = "TAG_E",
}

export interface IncidentMessageEntity {
    id: string;
    createdBy: string;
    createdAt: string;
    content: string;
    authorId: string;
}

export function convertAreaEntityToMapArea(areaEntity: AreaEntity, index?: number): MapArea {
    let mapArea: MapArea;
    const centerCoordinates = areaEntity.center?.coordinates;

    if (areaEntity?.radius && centerCoordinates) {
        mapArea = new Circle({ lng: centerCoordinates[0], lat: centerCoordinates[1] }, areaEntity.radius);
    } else {
        mapArea = new Polygon(areaEntity.geometry.coordinates.map((position) => position.map(([lng, lat]) => ({ lng, lat }))));
    }

    mapArea.data = {
        id: areaEntity.id,
        version: areaEntity.version,
        taskIds: areaEntity.taskIds,
        name: areaEntity.name,
    };

    return mapArea;
}

export function convertHandDrawingEntityResponseToHandDrawPolyline(entity: HandDrawingEntityResponse): HandDrawingPolyline {
    const polyline: HandDrawingPolyline = new Polyline(entity.geometry.coordinates.map(([lng, lat]) => ({ lng, lat })));

    polyline.data = {
        id: entity.id,
        version: entity.version,
    };

    return polyline;
}

export function convertCreateOrUpdateActionMarkerResponseBodyToActionMarkerData(
    response: CreateOrUpdateActionMarkerResponseBody,
    userId: string
): Partial<ActionMarkerData> {
    const icon = Object.entries(ActionMarkerIcon).find(([_, value]) => value === response.iconId)?.[0];

    return {
        id: response.id,
        name: response.description,
        location: response.location,
        photoId: response.photoId,
        tool: ACTION_MARKER_TOOLS.find((tool) => tool.name === MapToolName[icon as keyof typeof MapToolName]),
        isMine: response.authorId === userId,
    };
}

function convertCreateOrUpdateTaskResponseBodyToTask(response: CreateOrUpdateTaskResponseBody, userId: string): Task {
    const convertedTask = {
        ...response,
        isAirborne: response.airborne,
        attachedTeam: response.assignedTeam,
        details: response.notes,
        isMine: response.authorId === userId,
    };

    if (response.timeRange?.start) {
        convertedTask.timeRange.start = new Date(response.timeRange.start);
    }

    if (response.timeRange?.stop) {
        convertedTask.timeRange.stop = new Date(response.timeRange.stop);
    }

    return convertedTask;
}

export function convertIncidentMessageEntityToIncidentMessage(entity: IncidentMessageEntity, userId: string): IncidentMessage {
    return {
        id: entity.id,
        content: entity.content,
        createdBy: entity.createdBy,
        createdAt: new Date(entity.createdAt),
        isMine: entity.authorId === userId,
    };
}

export function convertGetOperationalSituationResponseBodyToOperationalSituation(
    response: GetOperationSituationResponseBody,
    userId: string
): OperationalSituation {
    return {
        incidentStatus: response.status,
        incidentArea: convertAreaEntityToMapArea(response.incidentArea),
        tasks: response.tasks.map((task) => convertCreateOrUpdateTaskResponseBodyToTask(task, userId)),
        teams: response.teams,
        areas: response.internalAreas.map((internalArea, index) => convertAreaEntityToMapArea(internalArea, index)),
        handDrawings: response.doodles.map((doodle) => convertHandDrawingEntityResponseToHandDrawPolyline(doodle)),
        actionMarkers: response.markers.map((marker) => convertCreateOrUpdateActionMarkerResponseBodyToActionMarkerData(marker, userId)),
        team: response.team,
        isPilotTaskApprovalRequired: response.pilotTaskApprovalRequired,
        messages: response.comments.map((comment) => convertIncidentMessageEntityToIncidentMessage(comment, userId)),
    };
}

export function convertActionMarkerDataToCreateOrUpdateActionMarkerRequestPayload(
    data: Partial<ActionMarkerData>
): CreateOrUpdateActionMarkerRequestPayload {
    const icon = Object.entries(MapToolName).find(([_, value]) => value === data.tool?.name)?.[0];

    return {
        description: data.name,
        iconId: ActionMarkerIcon[icon as keyof typeof ActionMarkerIcon],
        location: data.location,
    };
}

export function convertIMessageToIncidentEventMessage(message: IMessage, userId: string): IncidentEventMessage | undefined {
    const eventType = message.headers["event-type"] as unknown as IncidentEvent;
    const parsedBody = JSON.parse(message.body);

    switch (eventType) {
        case IncidentEvent.AreaCreated:
        case IncidentEvent.AreaUpdated:
            return { type: eventType, body: convertAreaEntityToMapArea(parsedBody) };
        case IncidentEvent.AreaRemoved:
            return { type: eventType, body: parsedBody };
        case IncidentEvent.AreaTaskAssignmentUpdated:
            return { type: eventType, body: parsedBody };
        case IncidentEvent.DoodleCreated:
            return { type: eventType, body: convertHandDrawingEntityResponseToHandDrawPolyline(parsedBody) };
        case IncidentEvent.MarkerCreated:
        case IncidentEvent.MarkerUpdated:
            return { type: eventType, body: convertCreateOrUpdateActionMarkerResponseBodyToActionMarkerData(parsedBody, userId) };
        case IncidentEvent.TaskCreated:
        case IncidentEvent.TaskUpdated:
            return { type: eventType, body: convertCreateOrUpdateTaskResponseBodyToTask(parsedBody, userId) };
        case IncidentEvent.TaskRemoved:
            return { type: eventType, body: parsedBody };
        case IncidentEvent.TeamCreated:
        case IncidentEvent.TeamRemoved:
            return { type: eventType, body: parsedBody };
        case IncidentEvent.TeamTaskAssignmentUpdated:
            return { type: eventType, body: parsedBody };
        case IncidentEvent.DoodleRemoved:
        case IncidentEvent.MarkerRemoved:
            return { type: eventType, body: (parsedBody as { id: string }).id };
        case IncidentEvent.CommentCreated:
            return { type: eventType, body: convertIncidentMessageEntityToIncidentMessage(parsedBody, userId) };
    }
}
