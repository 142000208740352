import { CommonModule, NgIf, NgOptimizedImage } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { RouterLink } from "@angular/router";
import { IncidentSharedDataState, IncidentSharedModule } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { LeafletMapModule } from "@dtm-frontend/shared/map/leaflet";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import { DmsCoordinatesModule } from "@dtm-frontend/shared/ui/dms-coordinates";
import { getTranslocoInlineLoader, LanguageCode, SharedI18nModule } from "@dtm-frontend/shared/ui/i18n";
import { TranslocoModule, TRANSLOCO_SCOPE } from "@ngneat/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { ActionMarkerAcknowledgeInfoToastComponent } from "./components/action-markers/action-marker-acknowledge-info-toast/action-marker-acknowledge-info-toast.component";
import { ActionMarkersPanelComponent } from "./components/action-markers/action-markers-panel.component";
import { ActiveIncidentComponent } from "./components/active-incident/active-incident.component";
import { AreaListComponent } from "./components/areas/area-list/area-list.component";
import { AreasPanelComponent } from "./components/areas/areas-panel.component";
import { ChatPanelComponent } from "./components/chat/chat-panel.component";
import { IncidentNavbarComponent } from "./components/incident-navbar/incident-navbar.component";
import { TaskCreatorComponent } from "./components/tasks/task-creator/task-creator.component";
import { TaskListComponent } from "./components/tasks/task-list/task-list.component";
import { TasksPanelComponent } from "./components/tasks/tasks-panel.component";
import { IncidentApiService } from "./services/incident-api.service";
import { IncidentState } from "./state/incident.state";

@NgModule({
    imports: [
        CommonModule,
        DmsCoordinatesModule,
        IncidentSharedModule,
        LeafletMapModule,
        LetModule,
        NgIf,
        NgOptimizedImage,
        NgxsModule.forFeature([IncidentSharedDataState, IncidentState]),
        MatProgressSpinnerModule,
        MatSlideToggleModule,
        PushModule,
        ReactiveFormsModule,
        RouterLink,
        SharedI18nModule,
        SharedUiModule,
        TranslocoModule,
    ],
    declarations: [
        ActionMarkersPanelComponent,
        ActiveIncidentComponent,
        ActionMarkerAcknowledgeInfoToastComponent,
        AreaListComponent,
        AreasPanelComponent,
        ChatPanelComponent,
        IncidentNavbarComponent,
        TaskCreatorComponent,
        TaskListComponent,
        TasksPanelComponent,
    ],
    providers: [
        IncidentApiService,
        {
            provide: TRANSLOCO_SCOPE,
            multi: true,
            useValue: {
                scope: "sahMobileLibIncident",
                loader: getTranslocoInlineLoader((language: LanguageCode) => import(`./assets/i18n/${language}.json`)),
            },
        },
    ],
    exports: [AreaListComponent, IncidentNavbarComponent, TaskCreatorComponent, TaskListComponent],
})
export class IncidentModule {
    public static forTest(): ModuleWithProviders<IncidentModule> {
        return {
            ngModule: IncidentModule,
            providers: [
                {
                    provide: IncidentApiService,
                    useValue: null,
                },
            ],
        };
    }
}
