import { AfterViewInit, ChangeDetectionStrategy, Component, Inject, Input, OnDestroy } from "@angular/core";
import {
    ActionMarkerData,
    ActionMarkerService,
    ActionMarkersLayerDirective,
    CustomLeafletMapEvent,
    IncidentSharedDataState,
    MapTool,
    MapToolName,
} from "@dtm-frontend/search-and-help-shared-lib/incident";
import { AnimationUtils, FunctionUtils, LOCAL_STORAGE } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ActiveToast, IndividualConfig, ToastrService } from "ngx-toastr";
import { distinctUntilChanged, map, tap, withLatestFrom } from "rxjs";
import { PanelType } from "../../models/incident.models";
import { IncidentActions } from "../../state/incident.actions";
import { IncidentState } from "../../state/incident.state";
import { ActionMarkerAcknowledgeInfoToastComponent } from "./action-marker-acknowledge-info-toast/action-marker-acknowledge-info-toast.component";

export const ACKNOWLEDGE_TOAST_ACTION = "acknowledgeToastAction";

const PANEL_VIEWS: PanelType[] = [
    PanelType.ActionMarkerCreator,
    PanelType.ActionMarkerEdit,
    PanelType.ActionMarkerIconChange,
    PanelType.ActionMarkerPreview,
    PanelType.ActionMarkers,
];
const LOCAL_STORAGE_HAS_SEEN_ADD_ACTION_MARKER_INFO = "sah-hasSeenAddActionMarkerInfo";
const LOCAL_STORAGE_HAS_SEEN_DRAG_ACTION_MARKER_INFO = "sah-hasSeenDragActionMarkerInfo";
const CONFIRM_TOAST_OPTIONS: Partial<IndividualConfig> = {
    disableTimeOut: true,
    closeButton: true,
    tapToDismiss: false,
    easeTime: 0,
};

@UntilDestroy()
@Component({
    selector: "sah-mobile-lib-action-markers-panel",
    templateUrl: "action-markers-panel.component.html",
    styleUrls: ["../../../shared/styles/incident.scss", "action-markers-panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInSideOffScreenDown()],
})
export class ActionMarkersPanelComponent implements AfterViewInit, OnDestroy {
    @Input() public markersLayerDirective: ActionMarkersLayerDirective | undefined;

    protected readonly PanelType = PanelType;

    protected readonly panelType$ = this.store.select(IncidentState.panelOpenState).pipe(
        distinctUntilChanged(),
        tap((panelType) => {
            this.clearActiveActionMarkersToasts();

            if (panelType[PanelType.ActionMarkers]) {
                this.displayAddActionMarkerToast();
            }
        })
    );
    protected readonly isProcessing$ = this.store.select(IncidentSharedDataState.isActionMarkerProcessing);
    protected readonly isExpanded$ = this.store
        .select(IncidentState.panelOpenState)
        .pipe(map((state) => PANEL_VIEWS.some((panelView) => state[panelView])));
    protected readonly selectedActionMarkerData$ = this.store.select(IncidentSharedDataState.selectedActionMarkerData);

    private addActionMarkerToast: ActiveToast<ActionMarkerAcknowledgeInfoToastComponent> | undefined;
    private dragActionMarkerToast: ActiveToast<ActionMarkerAcknowledgeInfoToastComponent> | undefined;

    constructor(
        @Inject(LOCAL_STORAGE) private readonly localStorage: Storage,
        private readonly store: Store,
        private toastrService: ToastrService,
        private translocoService: TranslocoService,
        protected readonly actionMarkerService: ActionMarkerService
    ) {
        this.watchMarkerSelectionChange();
    }

    public ngAfterViewInit() {
        this.watchMapEvents();
    }

    public ngOnDestroy() {
        this.markersLayerDirective?.mapInstance.then((mapInstance) => {
            mapInstance.off(CustomLeafletMapEvent.OnActionMarkerUpdate, this.openPreviewPanelAfterMarkerUpdate, this);
            mapInstance.off(CustomLeafletMapEvent.CreateActionMarker, this.closePanelAndClearMarkerSelectionOnMarkerCreation, this);
            mapInstance.off(CustomLeafletMapEvent.ClearActionMarkerSelection, this.closePanel, this);
            mapInstance.off(CustomLeafletMapEvent.RemoveActionMarker, this.closePanel, this);
        });
    }

    protected openPanel(panelType: PanelType): void {
        this.store.dispatch(new IncidentActions.OpenPanel(panelType));
    }

    protected closePanel(): void {
        this.store.dispatch(IncidentActions.ClosePanel);
    }

    protected selectMarkerToCreate(markerType: MapTool): void {
        this.store.dispatch(new IncidentActions.SelectMapTool(markerType.name));
    }

    protected clearMarkerSelection(): void {
        this.store.dispatch(new IncidentActions.SelectMapTool(MapToolName.Cursor));
        this.markersLayerDirective?.mapInstance.then((mapInstance) => mapInstance.fire(CustomLeafletMapEvent.ClearActionMarkerSelection));
    }

    protected addMarker(data: Partial<ActionMarkerData>): void {
        this.actionMarkerService.addActionMarker({
            ...this.store.selectSnapshot(IncidentSharedDataState.selectedActionMarkerData),
            ...data,
        });
    }

    protected updateMarker(data: Partial<ActionMarkerData>): void {
        this.actionMarkerService.updateActionMarker({
            ...this.store.selectSnapshot(IncidentSharedDataState.selectedActionMarkerData),
            ...data,
        });
    }

    protected updateMarkerIcon(mapTool: MapTool): void {
        this.updateMarker({ tool: mapTool });
        this.openPanel(PanelType.ActionMarkerPreview);
    }

    protected toggleDragActionMarkerToast(shouldBeVisible: boolean): void {
        if (shouldBeVisible) {
            this.displayDragActionMarkerToast();

            return;
        }

        this.clearActiveActionMarkersToasts();
    }

    private displayAddActionMarkerToast(): void {
        if (this.addActionMarkerToast || this.localStorage.getItem(LOCAL_STORAGE_HAS_SEEN_ADD_ACTION_MARKER_INFO)) {
            return;
        }

        this.addActionMarkerToast = this.toastrService.info(
            this.translocoService.translate("sahMobileLibIncident.actionMarkers.actionMarkerAcknowledgeInfoToast.addMessage"),
            undefined,
            {
                toastComponent: ActionMarkerAcknowledgeInfoToastComponent,
                ...CONFIRM_TOAST_OPTIONS,
            }
        );

        this.addActionMarkerToast.onAction.pipe(untilDestroyed(this)).subscribe((action) => {
            if (action === ACKNOWLEDGE_TOAST_ACTION) {
                this.localStorage.setItem(LOCAL_STORAGE_HAS_SEEN_ADD_ACTION_MARKER_INFO, "true");

                return;
            }
        });

        this.addActionMarkerToast.onHidden.pipe(untilDestroyed(this)).subscribe(() => {
            this.addActionMarkerToast = undefined;
        });
    }

    private displayDragActionMarkerToast(): void {
        if (this.dragActionMarkerToast || this.localStorage.getItem(LOCAL_STORAGE_HAS_SEEN_DRAG_ACTION_MARKER_INFO)) {
            return;
        }

        this.dragActionMarkerToast = this.toastrService.info(
            this.translocoService.translate("sahMobileLibIncident.actionMarkers.actionMarkerAcknowledgeInfoToast.dragMessage"),
            undefined,
            {
                toastComponent: ActionMarkerAcknowledgeInfoToastComponent,
                ...CONFIRM_TOAST_OPTIONS,
            }
        );

        this.dragActionMarkerToast.onAction.pipe(untilDestroyed(this)).subscribe((action) => {
            if (action === ACKNOWLEDGE_TOAST_ACTION) {
                this.localStorage.setItem(LOCAL_STORAGE_HAS_SEEN_ADD_ACTION_MARKER_INFO, "true");

                return;
            }
        });

        this.dragActionMarkerToast.onHidden.pipe(untilDestroyed(this)).subscribe(() => {
            this.dragActionMarkerToast = undefined;
        });
    }

    private clearActiveActionMarkersToasts(): void {
        if (this.addActionMarkerToast) {
            this.toastrService.remove(this.addActionMarkerToast.toastId);
            this.addActionMarkerToast = undefined;
        }

        if (this.dragActionMarkerToast) {
            this.toastrService.remove(this.dragActionMarkerToast.toastId);
            this.dragActionMarkerToast = undefined;
        }
    }

    private isMarkerDataNotEmpty(markerData: Partial<ActionMarkerData>) {
        return FunctionUtils.isTruthy(Object.keys(markerData).length);
    }

    private watchMarkerSelectionChange(): void {
        this.selectedActionMarkerData$
            .pipe(withLatestFrom(this.store.select(IncidentState.panelOpenState), this.isExpanded$), untilDestroyed(this))
            .subscribe(([selectedActionMarkerData, panelType, isExpanded]) => {
                if (FunctionUtils.isNullOrUndefined(selectedActionMarkerData) && isExpanded) {
                    if (panelType[PanelType.ActionMarkers]) {
                        return;
                    }

                    this.closePanel();

                    return;
                }

                if (
                    selectedActionMarkerData &&
                    this.isMarkerDataNotEmpty(selectedActionMarkerData) &&
                    selectedActionMarkerData.id === undefined
                ) {
                    this.openPanel(PanelType.ActionMarkerCreator);

                    return;
                }

                if (selectedActionMarkerData && this.isMarkerDataNotEmpty(selectedActionMarkerData)) {
                    this.openPanel(PanelType.ActionMarkerPreview);
                    if (selectedActionMarkerData.photoId && !selectedActionMarkerData.photo) {
                        this.actionMarkerService.loadActionMarkerPhoto(selectedActionMarkerData);
                    }

                    return;
                }
            });
    }

    private watchMapEvents(): void {
        this.markersLayerDirective?.mapInstance.then((mapInstance) => {
            mapInstance.on(CustomLeafletMapEvent.OnActionMarkerUpdate, this.openPreviewPanelAfterMarkerUpdate, this);
            mapInstance.on(CustomLeafletMapEvent.CreateActionMarker, this.closePanelAndClearMarkerSelectionOnMarkerCreation, this);
            mapInstance.on(CustomLeafletMapEvent.ClearActionMarkerSelection, this.closePanel, this);
            mapInstance.on(CustomLeafletMapEvent.RemoveActionMarker, this.closePanel, this);
        });
    }

    private closePanelAndClearMarkerSelectionOnMarkerCreation(): void {
        this.closePanel();
        this.clearMarkerSelection();
    }

    private openPreviewPanelAfterMarkerUpdate(): void {
        this.openPanel(PanelType.ActionMarkerPreview);
    }
}
