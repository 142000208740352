export enum DashboardErrorType {
    Unknown = "Unknown",
}

export interface DashboardError {
    type: DashboardErrorType;
}

export enum IncidentListType {
    MyActiveIncidents = "MyActiveIncidents",
    MyPlannedIncidents = "MyPlannedIncidents",
    MyCompletedIncidents = "MyCompletedIncidents",
}
