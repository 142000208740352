import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DashboardRoutingModule } from "@dtm-frontend/search-and-help-mobile-lib/dashboard";
import { IncidentRoutingModule } from "@dtm-frontend/search-and-help-mobile-lib/incident";
import { SahMobileAbsolutePath, SahMobilePath } from "@dtm-frontend/search-and-help-mobile-lib/shared";
import { LoginPageComponent, loginPageGuard } from "@dtm-frontend/search-and-help-shared-lib/shared";
import { DtmAuthGuard, DTM_AUTH_GUARD_REQUIRED_ROLES, NOT_AUTHORIZED_ROUTE } from "@dtm-frontend/shared/auth";
import { NotAuthorizedComponent } from "@dtm-frontend/shared/ui";

const routes: Routes = [
    {
        path: NOT_AUTHORIZED_ROUTE,
        component: NotAuthorizedComponent,
        canActivate: [DtmAuthGuard],
    },
    {
        path: SahMobilePath.Login,
        component: LoginPageComponent,
        canActivate: [loginPageGuard(SahMobileAbsolutePath.Dashboard)],
    },
    {
        path: "**",
        redirectTo: SahMobilePath.Dashboard,
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes), DashboardRoutingModule, IncidentRoutingModule],
    providers: [{ provide: DTM_AUTH_GUARD_REQUIRED_ROLES, useValue: [] }],
    exports: [RouterModule],
})
export class AppRoutingModule {}
