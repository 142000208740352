<dtm-ui-loader-container
    *ngrxLet="{
        incidentArea: incidentArea$,
        isIncidentValid: isIncidentValid$,
        isIncidentMapDataProcessing: isIncidentMapDataProcessing$,
        selectMapToolName: selectMapToolName$,
        isSelectionEnabled: isSelectionEnabled$,
        isActionMarkerCreationEnabled: isActionMarkerCreationEnabled$
    } as vm"
    [isShown]="vm.isIncidentMapDataProcessing"
>
    <div class="container">
        <dtm-map-leaflet-map>
            <sah-shared-lib-tasks-map-layer></sah-shared-lib-tasks-map-layer>

            <sah-shared-lib-action-markers-layer
                #actionMarkersLayerDirective="actionMarkersLayerDirective"
                [actionMarkers]="actionMarkers$ | ngrxPush"
                [selectedToolName]="vm.selectMapToolName"
                [isCreateModeEnabled]="vm.isActionMarkerCreationEnabled"
                [isSelectModeEnabled]="vm.isSelectionEnabled"
                (markerSelect)="actionMarkerService.selectActionMarker($event)"
                (markerDragEnd)="actionMarkerService.updateMarkerPosition($event)"
            ></sah-shared-lib-action-markers-layer>
        </dtm-map-leaflet-map>

        <div *ngIf="!vm.isIncidentValid && !vm.isIncidentMapDataProcessing" class="error-container">
            <dtm-ui-info-message type="error">
                <p>{{ "sahMobileLibIncident.incident.activeInvalidIncidentError" | transloco }}</p>
                <button type="button" class="button-tertiary" [routerLink]="SahMobileAbsolutePath.Dashboard">
                    {{ "sahMobileLibIncident.incident.goBackToHomePageButtonLabel" | transloco }}
                </button>
            </dtm-ui-info-message>
        </div>

        <ng-container *ngIf="vm.isIncidentValid">
            <section class="top-right-map-controls">
                <button type="button" class="button-icon map-button" (click)="openPanel(PanelType.Chat)">
                    <dtm-ui-icon name="chat"></dtm-ui-icon>
                </button>
            </section>

            <sah-mobile-lib-action-markers-panel
                [markersLayerDirective]="actionMarkersLayerDirective"
            ></sah-mobile-lib-action-markers-panel>
            <sah-mobile-lib-areas-panel></sah-mobile-lib-areas-panel>
            <sah-mobile-lib-tasks-panel></sah-mobile-lib-tasks-panel>
            <sah-mobile-lib-chat-panel></sah-mobile-lib-chat-panel>

            <sah-mobile-lib-incident-navbar
                (markersSelect)="openPanel(PanelType.ActionMarkers)"
                (areasSelect)="openPanel(PanelType.Areas)"
                (tasksSelect)="openPanel(PanelType.Tasks)"
            ></sah-mobile-lib-incident-navbar>
        </ng-container>
    </div>
</dtm-ui-loader-container>
