export enum OperatorType {
    Personal = "PERSONAL",
    Enterprise = "ENTERPRISE",
}

export interface ContextOperator {
    id: string;
    name: string;
    type: OperatorType;
    features: GlobalFeatures[];
    avatarUrl: string | undefined;
    permissions: GlobalOperatorPermissions[];
}

export enum GlobalFeatures {
    Payments = "PAYMENTS",
    OperationsManual = "OPERATIONS_MANUAL",
    Members = "MEMBERS",
    SpecificPermitApplication = "SPECIFIC_PERMIT_APPLICATION",
    MissionPlanner = "MISSION_PLANNER",
    Statements = "STATEMENTS",
    UseSpecificPermit = "USE_SPECIFIC_PERMIT",
}

export enum GlobalOperatorPermissions {
    OperatorManage = "Operator.MANAGE",
    OperatorRead = "Operator.READ",
    OperatorOperationsManualEdit = "OperatorOperationsManual.EDIT",
    OperatorOperationsManualRead = "OperatorOperationsManual.READ",
    OperatorMembersRead = "OperatorMembers.READ",
    OperatorMissionsPlan = "OperatorMissions.PLAN",
    OperatorMissionsPilot = "OperatorMissions.PILOT",
    OperatorMissionsRead = "OperatorMissions.READ",
    OperatorUavsUse = "OperatorUavs.USE",
    OperatorUavsShare = "OperatorUavs.SHARE",
    OperatorUavsManage = "OperatorUavs.MANAGE",
    OperatorUavModelsManage = "OperatorUavModels.MANAGE",
    OperatorUavModelsUse = "OperatorUavModels.USE",
    OperatorUavModelsNonCustomModelEdit = "OperatorUavModels.NON_CUSTOM_MODEL_EDIT",
    OperatorSpecificPermitsUse = "OperatorSpecificPermits.USE",
    OperatorSpecificPermitsCreateApplication = "OperatorSpecificPermits.CREATE_APPLICATION",
    OperatorSpecificPermitsApply = "OperatorSpecificPermits.APPLY",
    OperationalStatementMakeStatement = "OperationalStatement.MAKE_STATEMENT",
}
