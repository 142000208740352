import { ChangeDetectionStrategy, Component } from "@angular/core";
import { SahMobileAbsolutePath } from "@dtm-frontend/search-and-help-mobile-lib/shared";
import { AuthActions } from "@dtm-frontend/shared/auth";
import { Store } from "@ngxs/store";

const LOGO_HEIGHT_PX = 60;
const LOGO_WIDTH_PX = 149;

@Component({
    selector: "sah-mobile-menu",
    templateUrl: "menu.component.html",
    styleUrls: ["menu.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MenuComponent {
    protected readonly LOGO_HEIGHT_PX = LOGO_HEIGHT_PX;
    protected readonly LOGO_WIDTH_PX = LOGO_WIDTH_PX;
    protected readonly SahMobileAbsolutePath = SahMobileAbsolutePath;

    constructor(private readonly store: Store) {}

    protected logOut(): void {
        this.store.dispatch(AuthActions.Logout);
    }
}
