import { IconName } from "@dtm-frontend/shared/ui";
import { Circle, Marker, PathOptions, Polygon, Polyline } from "leaflet";
import { TaskStatus } from "./task.models";

export type MapArea = (Polygon & { data?: Partial<MapAreaData> }) | (Circle & { data?: Partial<MapAreaData> });
export interface MapAreaData {
    id: string;
    taskIds: string[];
    version: number;
    name: string | undefined;
}

export type TaskMarker = Marker & { data?: Partial<TaskMarkerData> };
export interface TaskMarkerData {
    areaId: string;
    taskId: string;
}

export type HandDrawingPolyline = Polyline & { data?: Partial<HandDrawingData> };
export interface HandDrawingData {
    id: string;
    version: number;
}

export type ActionMarker = Marker & { data?: Partial<ActionMarkerData> };
export interface ActionMarkerData {
    id: string;
    tool: ActionMarkerMapTool;
    name: string;
    location: { latitude: number; longitude: number };
    isDraggable: boolean;
    photo: Blob;
    photoId: string;
    hasPhotoBeenUpdated: boolean;
    isMine: boolean;
}

export enum CustomLeafletMapEvent {
    LoadArea = "LoadArea",
    RevertSelectedAreaPosition = "RevertSelectedAreaPosition",
    CompleteHandDrawing = "CompleteHandDrawing",
    LoadHandDrawing = "LoadHandDrawing",
    ClearHandDrawingSelection = "ClearHandDrawingSelection",
    CreateActionMarker = "CreateActionMarker",
    OnActionMarkerUpdate = "OnActionMarkerUpdate",
    OnActionMarkerUpdateFail = "OnActionMarkerUpdateFail",
    RemoveActionMarker = "RemoveActionMarker",
    ClearActionMarkerSelection = "ClearActionMarkerSelection",
    CreateTaskMarker = "CreateTaskMarker",
    UpdateTaskMarker = "UpdateTaskMarker",
    RemoveTaskMarker = "RemoveTaskMarker",
}

export enum MapToolName {
    Cursor = "Cursor",
    Hand = "Hand",
    DrawPencil = "DrawPencil",
    DrawLine = "DrawLine",
    DrawArrowStraight = "DrawArrowStraight",
    ShapeRectangle = "ShapeRectangle",
    ShapeCircle = "ShapeCircle",
    ShapePolygon = "ShapePolygon",
    Text = "Text",
    ActionMarker = "ActionMarker",
    StickyNote = "StickyNote",
    Ruler = "Ruler",
    ActionMarkerRepresentative = "ActionMarkerRepresentative",
    ActionMarkerGroup = "ActionMarkerGroup",
    ActionMarkerHelp = "ActionMarkerHelp",
    ActionMarkerAlert = "ActionMarkerAlert",
    ActionMarkerFire = "ActionMarkerFire",
    ActionMarkerWater = "ActionMarkerWater",
    ActionMarkerCamera = "ActionMarkerCamera",
    ActionMarkerFlag = "ActionMarkerFlag",
    ActionMarkerPlane = "ActionMarkerPlane",
    ActionMarkerHelicopter = "ActionMarkerHelicopter",
    ActionMarkerSailboat = "ActionMarkerSailboat",
    ActionMarkerCar = "ActionMarkerCar",
    ActionMarkerA = "ActionMarkerA",
    ActionMarkerB = "ActionMarkerB",
    ActionMarkerC = "ActionMarkerC",
    ActionMarkerD = "ActionMarkerD",
    ActionMarkerE = "ActionMarkerE",
}

export interface MapTool {
    name: MapToolName;
    icon?: IconName;
}

export const MAP_TOOLS: Record<MapToolName, MapTool> = {
    // NOTE: Primary map tools
    [MapToolName.Cursor]: { name: MapToolName.Cursor, icon: "cursor" },
    [MapToolName.Hand]: { name: MapToolName.Hand, icon: "hand" },
    [MapToolName.DrawPencil]: { name: MapToolName.DrawPencil, icon: "pencil" },
    [MapToolName.Ruler]: { name: MapToolName.Ruler, icon: "pencil-ruler" },
    [MapToolName.DrawLine]: { name: MapToolName.DrawLine, icon: "pen-nib" },
    [MapToolName.DrawArrowStraight]: { name: MapToolName.DrawArrowStraight, icon: "draw-arrow-straight" },
    [MapToolName.Text]: { name: MapToolName.Text, icon: "text" },
    [MapToolName.ActionMarker]: { name: MapToolName.ActionMarker, icon: "stamp" },
    [MapToolName.StickyNote]: { name: MapToolName.StickyNote, icon: "sticky-note" },
    // NOTE: Area drawing tools
    [MapToolName.ShapeRectangle]: { name: MapToolName.ShapeRectangle, icon: "rectangle" },
    [MapToolName.ShapeCircle]: { name: MapToolName.ShapeCircle, icon: "circle" },
    [MapToolName.ShapePolygon]: { name: MapToolName.ShapePolygon, icon: "polygon" },
    // NOTE: Action markers tools
    [MapToolName.ActionMarkerRepresentative]: { name: MapToolName.ActionMarkerRepresentative, icon: "nurse" },
    [MapToolName.ActionMarkerGroup]: { name: MapToolName.ActionMarkerGroup, icon: "team" },
    [MapToolName.ActionMarkerHelp]: { name: MapToolName.ActionMarkerHelp, icon: "user-help" },
    [MapToolName.ActionMarkerAlert]: { name: MapToolName.ActionMarkerAlert, icon: "error-warning" },
    [MapToolName.ActionMarkerFire]: { name: MapToolName.ActionMarkerFire, icon: "fire" },
    [MapToolName.ActionMarkerWater]: { name: MapToolName.ActionMarkerWater, icon: "drop" },
    [MapToolName.ActionMarkerCamera]: { name: MapToolName.ActionMarkerCamera, icon: "camera" },
    [MapToolName.ActionMarkerFlag]: { name: MapToolName.ActionMarkerFlag, icon: "finish" },
    [MapToolName.ActionMarkerPlane]: { name: MapToolName.ActionMarkerPlane, icon: "plane" },
    [MapToolName.ActionMarkerHelicopter]: { name: MapToolName.ActionMarkerHelicopter, icon: "helicopter-lpr" },
    [MapToolName.ActionMarkerSailboat]: { name: MapToolName.ActionMarkerSailboat, icon: "sailboat" },
    [MapToolName.ActionMarkerCar]: { name: MapToolName.ActionMarkerCar, icon: "car" },
    [MapToolName.ActionMarkerA]: { name: MapToolName.ActionMarkerA },
    [MapToolName.ActionMarkerB]: { name: MapToolName.ActionMarkerB },
    [MapToolName.ActionMarkerC]: { name: MapToolName.ActionMarkerC },
    [MapToolName.ActionMarkerD]: { name: MapToolName.ActionMarkerD },
    [MapToolName.ActionMarkerE]: { name: MapToolName.ActionMarkerE },
};

export const AREA_DRAWING_TOOLS: MapTool[] = [
    MAP_TOOLS[MapToolName.ShapeRectangle],
    MAP_TOOLS[MapToolName.ShapeCircle],
    MAP_TOOLS[MapToolName.ShapePolygon],
];

export const ACTION_MARKER_TOOLS: ActionMarkerMapTool[] = [
    MAP_TOOLS[MapToolName.ActionMarkerRepresentative],
    MAP_TOOLS[MapToolName.ActionMarkerGroup],
    MAP_TOOLS[MapToolName.ActionMarkerHelp],
    MAP_TOOLS[MapToolName.ActionMarkerAlert],
    MAP_TOOLS[MapToolName.ActionMarkerFire],
    MAP_TOOLS[MapToolName.ActionMarkerWater],
    MAP_TOOLS[MapToolName.ActionMarkerCamera],
    MAP_TOOLS[MapToolName.ActionMarkerFlag],
    MAP_TOOLS[MapToolName.ActionMarkerPlane],
    MAP_TOOLS[MapToolName.ActionMarkerHelicopter],
    MAP_TOOLS[MapToolName.ActionMarkerSailboat],
    MAP_TOOLS[MapToolName.ActionMarkerCar],
    { ...MAP_TOOLS[MapToolName.ActionMarkerA], letterIcon: "A" },
    { ...MAP_TOOLS[MapToolName.ActionMarkerB], letterIcon: "B" },
    { ...MAP_TOOLS[MapToolName.ActionMarkerC], letterIcon: "C" },
    { ...MAP_TOOLS[MapToolName.ActionMarkerD], letterIcon: "D" },
    { ...MAP_TOOLS[MapToolName.ActionMarkerE], letterIcon: "E" },
];

const TASK_STATUS_ACTIVE_COLOR = "#007544"; // NOTE: $color-status-success: #007544
const TASK_STATUS_COMPLETED_COLOR = "#8d99b1"; // NOTE: $color-gray-200: #8d99b1
const TASK_STATUS_COMPLETED_FILL_COLOR = "#223d6b"; // NOTE: $color-gray-500: #223d6b
const TASK_STATUS_INCOMPLETE_COLOR = "#b9c1d0"; // NOTE: $color-gray-100: #b9c1d0
const TASK_STATUS_INCOMPLETE_FILL_COLOR = "#ffffff"; // NOTE: $color-white: #ffffff
const TASK_STATUS_PAUSED_COLOR = "#ffb300"; // NOTE: $color-primary-600: #ffb300
const TASK_STATUS_PENDING_ACCEPTANCE_COLOR = "#3fa6fd"; // NOTE: $color-secondary-400: #3fa6fd
const TASK_STATUS_PLANNED_COLOR = "#223d6b"; // NOTE: $color-gray-500: #223d6b
const TASK_STATUS_REJECTED_COLOR = "#8d99b1"; // NOTE: $color-gray-200: #8d99b1
const TASK_STATUS_REJECTED_FILL_COLOR = "#223d6b"; // NOTE: $color-gray-500: #223d6b

export const MAP_AREA_PATH_OPTIONS: Record<TaskStatus, PathOptions> = {
    [TaskStatus.Active]: {
        color: TASK_STATUS_ACTIVE_COLOR,
        fillColor: TASK_STATUS_ACTIVE_COLOR,
        fillOpacity: 0.2,
        dashArray: undefined,
    },
    [TaskStatus.Completed]: {
        color: TASK_STATUS_COMPLETED_COLOR,
        fillColor: TASK_STATUS_COMPLETED_FILL_COLOR,
        fillOpacity: 0.2,
        dashArray: undefined,
    },
    [TaskStatus.Incomplete]: {
        color: TASK_STATUS_INCOMPLETE_COLOR,
        fillColor: TASK_STATUS_INCOMPLETE_FILL_COLOR,
        fillOpacity: 0.4,
        dashArray: undefined,
    },
    [TaskStatus.Paused]: {
        color: TASK_STATUS_PAUSED_COLOR,
        fillColor: TASK_STATUS_PAUSED_COLOR,
        fillOpacity: 0.2,
        dashArray: undefined,
    },
    [TaskStatus.PendingAcceptance]: {
        color: TASK_STATUS_PENDING_ACCEPTANCE_COLOR,
        fillColor: TASK_STATUS_PENDING_ACCEPTANCE_COLOR,
        fillOpacity: 0.2,
        dashArray: undefined,
    },
    [TaskStatus.Planned]: {
        color: TASK_STATUS_PLANNED_COLOR,
        fillColor: TASK_STATUS_PLANNED_COLOR,
        fillOpacity: 0.1,
        dashArray: "5",
    },
    [TaskStatus.Rejected]: {
        color: TASK_STATUS_REJECTED_COLOR,
        fillColor: TASK_STATUS_REJECTED_FILL_COLOR,
        fillOpacity: 0.2,
        dashArray: "5",
    },
};

const DEFAULT_COLOR = "#0d254d"; // NOTE: $color-gray-800: #0d254d
export const DEFAULT_MAP_AREA_PATH_OPTIONS: PathOptions = {
    color: DEFAULT_COLOR,
    fillColor: DEFAULT_COLOR,
    fillOpacity: 0.1,
    dashArray: undefined,
};
export const DEFAULT_MAP_AREA_HINT_LINE_STYLE: PathOptions = {
    ...DEFAULT_MAP_AREA_PATH_OPTIONS,
    // eslint-disable-next-line no-magic-numbers
    dashArray: [5, 5],
};

const INCIDENT_AREA_COLOR = "#0849a4"; // NOTE: $color-secondary-900: #0849a4;
export const INCIDENT_AREA_PATH_OPTIONS: PathOptions = {
    color: INCIDENT_AREA_COLOR,
    fill: false,
};

export const TASK_STATUS_IMPORTANCE_ORDER: TaskStatus[] = [
    TaskStatus.Active,
    TaskStatus.PendingAcceptance,
    TaskStatus.Paused,
    TaskStatus.Planned,
    TaskStatus.Completed,
    TaskStatus.Incomplete,
    TaskStatus.Rejected,
];

export enum SidePanelViewType {
    AreaList = "AreaList",
    AreaPreview = "AreaPreview",
    AreaAssignTask = "AreaAssignTask",
    TaskList = "TaskList",
    TaskCreator = "TaskCreator",
    TaskEditor = "TaskEditor",
    TeamCreator = "TeamCreator",
    MapLayers = "MapLayers",
    Chat = "Chat",
}

export type SidePanelViewOpenState = {
    [key in SidePanelViewType]: boolean;
};

export enum MapFilter {
    ActionMarkers = "ActionMarkers",
    Areas = "Areas",
    AreasWithCompletedTasksOnly = "AreasWithCompletedTasksOnly",
    AreasWithPlannedTasksOnly = "AreasWithPlannedTasksOnly",
    HandDrawingsAndNotes = "HandDrawingsAndNotes",
}

export type MapFiltersState = {
    [key in MapFilter]: boolean;
};

export type ActionMarkerMapTool = MapTool & { letterIcon?: string };
