<ion-menu #ionMenu side="start" content-id="main" [swipeGesture]="false">
    <ion-header>
        <ion-toolbar>
            <div class="logo">
                <a [routerLink]="SahMobileAbsolutePath.Dashboard" (click)="ionMenu.close()">
                    <img ngSrc="assets/images/sah-mobile-logo.svg" alt="logo" [height]="LOGO_HEIGHT_PX" [width]="LOGO_WIDTH_PX" />
                </a>
            </div>
        </ion-toolbar>
    </ion-header>

    <ion-content>
        <ion-list>
            <div class="menu-items">
                <button
                    type="button"
                    class="button-secondary menu-item"
                    routerLinkActive="menu-item-active"
                    [routerLink]="SahMobileAbsolutePath.Dashboard"
                    (click)="ionMenu.close()"
                >
                    <dtm-ui-icon slot="start" name="home"></dtm-ui-icon>
                    <span>{{ "sahMobile.menuItems.dashboard" | transloco }}</span>
                </button>
            </div>

            <div class="footer">
                <button type="button" class="button-secondary menu-item" (click)="ionMenu.close(); logOut()">
                    <dtm-ui-icon name="logout-circle"></dtm-ui-icon>
                    <span>{{ "sahMobile.logOutButtonLabel" | transloco }}</span>
                </button>
            </div>
        </ion-list>
    </ion-content>
</ion-menu>
