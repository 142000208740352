import { ChangeDetectionStrategy, Component } from "@angular/core";
import { Toast } from "ngx-toastr";
import { ACKNOWLEDGE_TOAST_ACTION } from "../action-markers-panel.component";

@Component({
    templateUrl: "action-marker-acknowledge-info-toast.component.html",
    styleUrls: ["action-marker-acknowledge-info-toast.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActionMarkerAcknowledgeInfoToastComponent extends Toast {
    protected acknowledgeAndClose(): void {
        this.toastPackage.triggerAction(ACKNOWLEDGE_TOAST_ACTION);
        this.remove();
    }
}
