<button type="button" class="button-secondary" (click)="markersSelect.emit()">
    <dtm-ui-icon name="stamp"></dtm-ui-icon>
    {{ "sahMobileLibIncident.incidentNavbar.markersButtonLabel" | transloco }}
</button>
<div class="divider"></div>
<button type="button" class="button-secondary" (click)="areasSelect.emit()">
    <dtm-ui-icon name="shapes"></dtm-ui-icon>
    {{ "sahMobileLibIncident.incidentNavbar.areasButtonLabel" | transloco }}
</button>
<div class="divider"></div>
<button type="button" class="button-secondary" (click)="tasksSelect.emit()">
    <dtm-ui-icon name="todo"></dtm-ui-icon>
    {{ "sahMobileLibIncident.incidentNavbar.tasksButtonLabel" | transloco }}
</button>
