import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AreaService, IncidentSharedDataState, MapArea } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { AnimationUtils } from "@dtm-frontend/shared/utils";
import { Store } from "@ngxs/store";
import { map } from "rxjs";
import { PanelType } from "../../models/incident.models";
import { IncidentActions } from "../../state/incident.actions";
import { IncidentState } from "../../state/incident.state";

const PANEL_VIEWS: PanelType[] = [PanelType.Areas];

@Component({
    selector: "sah-mobile-lib-areas-panel",
    templateUrl: "areas-panel.component.html",
    styleUrls: ["../../../shared/styles/incident.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInSideOffScreenDown()],
})
export class AreasPanelComponent {
    protected readonly PanelType = PanelType;

    protected readonly panelType$ = this.store.select(IncidentState.panelOpenState);
    protected readonly isExpanded$ = this.panelType$.pipe(map((state) => PANEL_VIEWS.some((panelView) => state[panelView])));
    protected readonly areas$ = this.store.select(IncidentSharedDataState.areas);

    constructor(private readonly areaService: AreaService, private readonly store: Store) {}

    protected closePanel(): void {
        this.store.dispatch(IncidentActions.ClosePanel);
    }

    protected showAreaOnMap(area: MapArea): void {
        this.closePanel();
        this.areaService.showAreaOnMap(area);
    }
}
