import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { DtmRoleGuard } from "@dtm-frontend/shared/auth";
import { SahMobilePath } from "../shared/defaults/paths";
import { ActiveIncidentComponent } from "./components/active-incident/active-incident.component";

const routes: Routes = [
    {
        path: SahMobilePath.ActiveIncident,
        component: ActiveIncidentComponent,
        canActivate: [DtmRoleGuard],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class IncidentRoutingModule {}
