<div class="toast-icon">
    <dtm-ui-icon name="information-fill"></dtm-ui-icon>
</div>
<section class="text-container">
    <div>{{ toastPackage.message }}</div>
    <button type="button" class="button-secondary" (click)="acknowledgeAndClose()">
        <dtm-ui-icon name="eye-off"></dtm-ui-icon>
        {{ "sahMobileLibIncident.actionMarkers.actionMarkerAcknowledgeInfoToast.acknowledgeButtonLabel" | transloco }}
    </button>
</section>
<button type="button" class="toast-close-button" (click)="remove()"><dtm-ui-icon name="close"></dtm-ui-icon></button>
