import { IncidentCategory, IncidentList } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { PageResponseBody } from "@dtm-frontend/shared/ui";

export type GetIncidentsResponseBody = PageResponseBody<{
    id: string;
    name: string;
    categories: IncidentCategory[];
    leadInstitution: {
        name: string;
        unit: string;
    };
    numberOfTasks: {
        active: number;
        all: number;
        unassigned: number;
    };
}>;

export function convertGetIncidentsResponseBodyToIncidentList(response: GetIncidentsResponseBody): IncidentList {
    return {
        content: response.content.map((incident) => ({
            id: incident.id,
            name: incident.name,
            categories: incident.categories,
            leadInstitution: `${incident.leadInstitution.name} ${incident.leadInstitution.unit}`,
        })),
        page: {
            pageNumber: response.number,
            pageSize: response.size,
            totalElements: response.totalElements,
        },
    };
}
