import { IconName, Page } from "@dtm-frontend/shared/ui";
import { ActionMarkerData, HandDrawingPolyline, MapArea } from "./incident-map.models";
import { Task, TaskStatus } from "./task.models";
import { Team, TeamStatus } from "./team.models";

export enum IncidentStatus {
    Planned = "PLANNED",
    Active = "ACTIVE",
    Completed = "COMPLETED",
}

export enum IncidentCategory {
    Fire = "FIRE",
    MassEvent = "MASS_EVENT",
    SearchAndRescueOperations = "SEARCH_AND_RESCUE_OPERATIONS",
    TechnicalFailureOrReleaseOfDangerousSubstances = "TECHNICAL_FAILURE_OR_RELEASE_OF_DANGEROUS_SUBSTANCES",
    TerroristThreat = "TERRORIST_THREAT",
    SocialUnrest = "SOCIAL_UNREST",
    EffectsOfStrongWinds = "EFFECTS_OF_STRONG_WINDS",
    FloodOrFlooding = "FLOOD_OR_FLOODING",
    TrafficAccidentOrDisasterInLandTraffic = "TRAFFIC_ACCIDENT_OR_DISASTER_IN_LAND_TRAFFIC",
    Other = "OTHER",
}

export interface IncidentListItem {
    id: string;
    name: string;
    categories: IncidentCategory[];
    leadInstitution: string;
}

export interface IncidentList {
    content: IncidentListItem[];
    page: Page;
}

export enum IncidentListActionType {
    Link = "Link",
    Button = "Button",
}

export type IncidentListAction = {
    labelKey: string;
    iconName: IconName;
} & (
    | {
          type: IncidentListActionType.Link;
          link: string;
      }
    | {
          type: IncidentListActionType.Button;
          run: (incident: IncidentListItem) => void;
      }
);

export enum IncidentErrorType {
    Unknown = "Unknown",
    Conflict = "Conflict",
    AreaOutOfIncidentBoundary = "AreaOutOfIncidentBoundary",
}

export interface IncidentError {
    type: IncidentErrorType;
    messageKey: string;
    args: Record<string, unknown>;
}

export interface OperationalSituation {
    incidentStatus: IncidentStatus;
    incidentArea: MapArea | undefined;
    tasks: Task[];
    areas: MapArea[];
    handDrawings: HandDrawingPolyline[];
    actionMarkers: Partial<ActionMarkerData>[];
    teams: Team[];
    team: Team;
    isPilotTaskApprovalRequired: boolean;
    messages: IncidentMessage[];
}

// NOTE: Commented out events will be enabled later when related functionality is implemented
export enum IncidentEvent {
    AreaCreated = "AreaCreated",
    AreaUpdated = "AreaUpdated",
    AreaRemoved = "AreaRemoved",
    AreaTaskAssignmentUpdated = "AreaTaskAssignmentUpdated",
    DoodleCreated = "DoodleCreated",
    // DoodleUpdated = "DoodleUpdated",
    DoodleRemoved = "DoodleRemoved",
    MarkerCreated = "MarkerCreated",
    MarkerUpdated = "MarkerUpdated",
    MarkerRemoved = "MarkerRemoved",
    // NoteCreated = "NoteCreated",
    // NoteUpdated = "NoteUpdated",
    // NoteRemoved = "NoteRemoved",
    TaskCreated = "TaskCreated",
    TaskUpdated = "TaskUpdated",
    TaskRemoved = "TaskRemoved",
    TeamCreated = "TeamCreated",
    // TeamUpdated = "TeamUpdated",
    TeamRemoved = "TeamRemoved",
    TeamTaskAssignmentUpdated = "TeamTaskAssignmentUpdated",
    CommentCreated = "CommentCreated",
}

export enum TaskAssignmentUpdateType {
    Assign = "ASSIGN",
    Unassign = "UNASSIGN",
}

export interface TeamTaskAssignmentUpdatedEventBody {
    assignedTeamUpdate?: {
        id: string;
        teamId: string;
        name: string;
        status: TeamStatus;
        version: number;
    };
    taskStatusUpdate: { id: string; status: TaskStatus; version: number };
    assignmentType: TaskAssignmentUpdateType;
}

export interface AreaTaskAssignmentUpdatedEventBody {
    areaVersionUpdate: { areaId: string; version: number };
    taskStatusUpdate: { id: string; status: TaskStatus; version: number };
    assignmentType: TaskAssignmentUpdateType;
}

export interface AreaRemovedEventBody {
    id: string;
    taskStatusUpdates: [{ id: string; status: TaskStatus; version: number }];
}

export interface TaskRemovedEventBody {
    id: string;
    areaVersionUpdated: { areaId: string; version: number } | undefined;
}

export interface TeamRemovedEventBody {
    id: string;
    name: string;
    taskStatusUpdates: Array<{ id: string; status: TaskStatus; version: number }>;
}

export type IncidentEventMessage =
    | { type: IncidentEvent.AreaCreated | IncidentEvent.AreaUpdated; body: MapArea }
    | { type: IncidentEvent.AreaRemoved; body: AreaRemovedEventBody }
    | { type: IncidentEvent.AreaTaskAssignmentUpdated; body: AreaTaskAssignmentUpdatedEventBody }
    | { type: IncidentEvent.DoodleCreated; body: HandDrawingPolyline }
    | { type: IncidentEvent.MarkerCreated | IncidentEvent.MarkerUpdated; body: Partial<ActionMarkerData> }
    | { type: IncidentEvent.TaskCreated | IncidentEvent.TaskUpdated; body: Task }
    | { type: IncidentEvent.TaskRemoved; body: TaskRemovedEventBody }
    | { type: IncidentEvent.TeamCreated; body: Team }
    | { type: IncidentEvent.TeamRemoved; body: TeamRemovedEventBody }
    | { type: IncidentEvent.TeamTaskAssignmentUpdated; body: TeamTaskAssignmentUpdatedEventBody }
    | {
          type: IncidentEvent.DoodleRemoved | IncidentEvent.MarkerRemoved;
          body: string;
      }
    | { type: IncidentEvent.CommentCreated; body: IncidentMessage };

export interface IncidentMessage {
    id: string;
    createdBy: string;
    createdAt: Date;
    content: string;
    isMine: boolean;
}
