<section class="sah-card grid">
    <h2>{{ "sahMobileLibDashboard.dashboard.myIncidentsListHeader" | transloco }}</h2>

    <div *ngrxLet="isProcessing$ | ngrxPush as isProcessing" class="dtm-tabs">
        <mat-tab-group
            *ngrxLet="myIncidentsSelectedTabIndex$ as myIncidentsSelectedTabIndex"
            dynamicHeight
            color="accent"
            [selectedIndex]="myIncidentsSelectedTabIndex"
            (selectedIndexChange)="changeMyIncidentsTab($event)"
        >
            <mat-tab [label]="'sahMobileLibDashboard.dashboard.activeIncidentsLabel' | transloco">
                <sah-shared-lib-incident-list
                    [isProcessing]="isProcessing"
                    [incidents]="myActiveIncidents$ | ngrxPush"
                    [incidentsPage]="myActiveIncidentsPage$ | ngrxPush"
                    [hasGetListErrorOccurred]="hasMyActiveIncidentsError$ | ngrxPush"
                    [actions]="MY_ACTIVE_INCIDENTS_ACTIONS"
                    (pageReload)="getList(IncidentListType.MyActiveIncidents)"
                    (pageChange)="getList(IncidentListType.MyActiveIncidents, $event)"
                ></sah-shared-lib-incident-list>
            </mat-tab>
            <mat-tab [label]="'sahMobileLibDashboard.dashboard.plannedIncidentsLabel' | transloco">
                <sah-shared-lib-incident-list
                    [isProcessing]="isProcessing"
                    [incidents]="myPlannedIncidents$ | ngrxPush"
                    [incidentsPage]="myPlannedIncidentsPage$ | ngrxPush"
                    [hasGetListErrorOccurred]="hasMyPlannedIncidentsError$ | ngrxPush"
                    (pageReload)="getList(IncidentListType.MyPlannedIncidents)"
                    (pageChange)="getList(IncidentListType.MyPlannedIncidents, $event)"
                ></sah-shared-lib-incident-list>
            </mat-tab>
            <mat-tab [label]="'sahMobileLibDashboard.dashboard.completedIncidentsLabel' | transloco">
                <sah-shared-lib-incident-list
                    [isProcessing]="isProcessing"
                    [incidents]="myCompletedIncidents$ | ngrxPush"
                    [incidentsPage]="myCompletedIncidentsPage$ | ngrxPush"
                    [hasGetListErrorOccurred]="hasMyCompletedIncidentsError$ | ngrxPush"
                    (pageReload)="getList(IncidentListType.MyCompletedIncidents)"
                    (pageChange)="getList(IncidentListType.MyCompletedIncidents, $event)"
                >
                </sah-shared-lib-incident-list>
            </mat-tab>
        </mat-tab-group>
    </div>
</section>
