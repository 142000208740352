import { ChangeDetectionStrategy, Component } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { IncidentListAction, IncidentListActionType } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";
import { UntilDestroy } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { map } from "rxjs/operators";
import { SahMobileAbsolutePath } from "../../../shared/defaults/paths";
import { IncidentListType } from "../../models/dashboard.models";
import { DashboardActions } from "../../state/dashboard.actions";
import { DashboardState } from "../../state/dashboard.state";

interface DashboardComponentState {
    myIncidentsSelectedTabIndex: number;
}

enum DashboardTab {
    MyActiveIncidents = 0,
    MyPlannedIncidents = 1,
    MyCompletedIncidents = 2,
}

@UntilDestroy()
@Component({
    selector: "sah-mobile-lib-dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class DashboardComponent {
    protected readonly IncidentListType = IncidentListType;
    protected readonly MY_ACTIVE_INCIDENTS_ACTIONS: IncidentListAction[] = [
        {
            iconName: "arrow-go",
            type: IncidentListActionType.Link,
            labelKey: "sahMobileLibDashboard.incidentList.goToIncidentActionLabel",
            link: SahMobileAbsolutePath.ActiveIncident,
        },
    ];

    protected readonly myIncidentsSelectedTabIndex$ = this.localStore.selectByKey("myIncidentsSelectedTabIndex");

    protected readonly isProcessing$ = this.store.select(DashboardState.isProcessing);
    protected readonly myActiveIncidents$ = this.store.select(DashboardState.myActiveIncidents);
    protected readonly myActiveIncidentsPage$ = this.store.select(DashboardState.myActiveIncidentsPage);
    protected readonly hasMyActiveIncidentsError$ = this.store.select(DashboardState.myActiveIncidentsError).pipe(map((error) => !!error));
    protected readonly myPlannedIncidents$ = this.store.select(DashboardState.myPlannedIncidents);
    protected readonly myPlannedIncidentsPage$ = this.store.select(DashboardState.myPlannedIncidentsPage);
    protected readonly hasMyPlannedIncidentsError$ = this.store
        .select(DashboardState.myPlannedIncidentsError)
        .pipe(map((error) => !!error));
    protected readonly myCompletedIncidents$ = this.store.select(DashboardState.myCompletedIncidents);
    protected readonly myCompletedIncidentsPage$ = this.store.select(DashboardState.myCompletedIncidentsPage);
    protected readonly hasMyCompletedIncidentsError$ = this.store
        .select(DashboardState.myCompletedIncidentsError)
        .pipe(map((error) => !!error));

    constructor(private readonly localStore: LocalComponentStore<DashboardComponentState>, private readonly store: Store) {
        this.localStore.setState({
            myIncidentsSelectedTabIndex: 0,
        });
        this.getList(IncidentListType.MyActiveIncidents);
    }

    protected changeMyIncidentsTab(tabIndex: number) {
        switch (tabIndex) {
            case DashboardTab.MyPlannedIncidents:
                this.localStore.patchState({ myIncidentsSelectedTabIndex: DashboardTab.MyPlannedIncidents });
                this.getList(IncidentListType.MyPlannedIncidents);
                break;
            case DashboardTab.MyCompletedIncidents:
                this.localStore.patchState({
                    myIncidentsSelectedTabIndex: DashboardTab.MyCompletedIncidents,
                });
                this.getList(IncidentListType.MyCompletedIncidents);
                break;
            default:
                this.localStore.patchState({
                    myIncidentsSelectedTabIndex: DashboardTab.MyActiveIncidents,
                });
                this.getList(IncidentListType.MyActiveIncidents);
        }
    }

    protected getList(listType: IncidentListType, pageEvent?: PageEvent): void {
        switch (listType) {
            case IncidentListType.MyActiveIncidents:
                this.store.dispatch(new DashboardActions.GetMyActiveIncidents(pageEvent));
                break;
            case IncidentListType.MyPlannedIncidents:
                this.store.dispatch(new DashboardActions.GetMyPlannedIncidents(pageEvent));
                break;
            case IncidentListType.MyCompletedIncidents:
                this.store.dispatch(new DashboardActions.GetMyCompletedIncidents(pageEvent));
                break;
        }
    }
}
