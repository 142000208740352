<ng-container *ngrxLet="{ tasks: tasks$ } as vm">
    <div *ngIf="vm.tasks.length; else emptyListTemplate" class="task-list">
        <sah-shared-lib-task-card *ngFor="let task of vm.tasks" [task]="task" [isStatusChangeEnabled]="false" [isTeamChangeEnabled]="false">
            <ng-container actions>
                <button type="button" class="button-icon" (click)="taskPreview.emit(task)">
                    <dtm-ui-icon name="arrow-go"></dtm-ui-icon>
                </button>
            </ng-container>
        </sah-shared-lib-task-card>
    </div>

    <ng-template #emptyListTemplate>
        <dtm-ui-empty-state [mode]="EmptyStateMode.Tertiary">
            <img imageSlot [ngSrc]="'assets/images/no-results.svg'" width="100" height="100" alt="" />
            <ng-container messageSlot>
                {{ "sahMobileLibIncident.taskList.emptyListMessage" | transloco }}
            </ng-container>
        </dtm-ui-empty-state>
    </ng-template>
</ng-container>
