import { ActionMarkerData } from "../models/incident-map.models";

export namespace IncidentSharedDataActions {
    export class ResetState {
        public static readonly type = "[IncidentSharedData] Reset state";
    }

    export class StartIncidentUpdatesWatch {
        public static readonly type = "[IncidentSharedData] Start incident updates watch";
        constructor(public incidentId: string) {}
    }

    export class StopIncidentUpdatesWatch {
        public static readonly type = "[IncidentSharedData] Stop incident updates watch";
    }

    export class GetOperationalSituation {
        public static readonly type = "[IncidentSharedData] Get operational situation";
        constructor(public incidentId: string) {}
    }

    export class CreateActionMarker {
        public static readonly type = "[IncidentSharedData] Create action marker";
        constructor(public markerData: Partial<ActionMarkerData>) {}
    }

    export class UpdateActionMarker {
        public static readonly type = "[IncidentSharedData] Update action marker";
        constructor(public markerData: Partial<ActionMarkerData>) {}
    }

    export class AddActionMarkerPhoto {
        public static readonly type = "[IncidentSharedData] Add action marker photo";
        constructor(public markerData: Partial<ActionMarkerData>) {}
    }

    export class RemoveActionMarkerPhoto {
        public static readonly type = "[IncidentSharedData] Remove action marker photo";
        constructor(public markerData: Partial<ActionMarkerData>) {}
    }

    export class GetActionMarkerPhoto {
        public static readonly type = "[IncidentSharedData] Get action marker photo";
        constructor(public markerData: Partial<ActionMarkerData>) {}
    }

    export class RemoveActionMarker {
        public static readonly type = "[IncidentSharedData] Remove action marker";
        constructor(public markerId: string) {}
    }

    export class SelectActionMarker {
        public static readonly type = "[IncidentSharedData] Select action marker";
        constructor(public marker: Partial<ActionMarkerData> | undefined) {}
    }

    export class SendIncidentMessage {
        public static readonly type = "[IncidentSharedData] Send incident message";
        constructor(public message: string) {}
    }
}
