import { ChangeDetectionStrategy, Component } from "@angular/core";
import { IncidentSharedDataActions, IncidentSharedDataState } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { AnimationUtils } from "@dtm-frontend/shared/utils";
import { TranslocoService } from "@ngneat/transloco";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { ToastrService } from "ngx-toastr";
import { map } from "rxjs";
import { PanelType } from "../../models/incident.models";
import { IncidentActions } from "../../state/incident.actions";
import { IncidentState } from "../../state/incident.state";

@UntilDestroy()
@Component({
    selector: "sah-mobile-lib-chat-panel",
    templateUrl: "chat-panel.component.html",
    styleUrls: ["../../../shared/styles/incident.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [AnimationUtils.slideInSideOffScreenDown()],
})
export class ChatPanelComponent {
    protected readonly isProcessing$ = this.store.select(IncidentState.isProcessing);
    protected readonly isExpanded$ = this.store.select(IncidentState.panelOpenState).pipe(map((state) => state[PanelType.Chat]));
    protected readonly messages$ = this.store.select(IncidentSharedDataState.messages);

    constructor(
        private readonly toastrService: ToastrService,
        private readonly translocoService: TranslocoService,
        private readonly store: Store
    ) {}

    protected closePanel(): void {
        this.store.dispatch(IncidentActions.ClosePanel);
    }

    protected sendMessage(message: string): void {
        this.store
            .dispatch(new IncidentSharedDataActions.SendIncidentMessage(message))
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                const error = this.store.selectSnapshot(IncidentSharedDataState.sendIncidentMessageError);
                if (error) {
                    this.toastrService.error(this.translocoService.translate("sahMobileLibIncident.chat.sendIncidentMessageError"));
                }
            });
    }
}
