import { BooleanInput, coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { TaskCreatorFormValues, TaskCreatorPilotFormValues } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { InvalidFormScrollableDirective } from "@dtm-frontend/shared/ui";
import { GeographicCoordinatesType } from "@dtm-frontend/shared/ui/dms-coordinates";
import { DateUtils, LocalComponentStore } from "@dtm-frontend/shared/utils";

interface TaskCreatorComponentState {
    isProcessing: boolean;
    isPilotTaskApprovalRequired: boolean;
}

const DEFAULT_START_TIME_ADD_MINUTES = 5;
const DEFAULT_END_TIME_ADD_MINUTES = 35;
const AREA_RADIUS_MIN = 1;

@Component({
    selector: "sah-mobile-lib-task-creator",
    templateUrl: "task-creator.component.html",
    styleUrls: ["task-creator.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class TaskCreatorComponent implements OnDestroy {
    @ViewChild(InvalidFormScrollableDirective) private readonly invalidFormScrollable!: InvalidFormScrollableDirective;

    @Input() public set isPilotTaskApprovalRequired(value: BooleanInput) {
        this.localStore.patchState({ isPilotTaskApprovalRequired: coerceBooleanProperty(value) });
    }

    @Input() public set isProcessing(value: BooleanInput) {
        this.localStore.patchState({ isProcessing: coerceBooleanProperty(value) });
    }

    @Input() public set isDefaultDateSet(value: BooleanInput) {
        if (coerceBooleanProperty(value)) {
            this.taskCreatorForm.controls.data.patchValue({
                startDate: this.MIN_DATE,
                startTime: DateUtils.addMinutes(this.MIN_DATE, DEFAULT_START_TIME_ADD_MINUTES),
                endDate: this.MIN_DATE,
                endTime: DateUtils.addMinutes(this.MIN_DATE, DEFAULT_END_TIME_ADD_MINUTES),
            });
        }
    }

    @Output() protected readonly taskSubmit = new EventEmitter<TaskCreatorPilotFormValues>();
    @Output() protected readonly cancel = new EventEmitter<void>();
    @Output() protected readonly cleanUp = new EventEmitter<void>();

    protected readonly MIN_DATE = new Date();
    protected readonly GeographicCoordinatesType = GeographicCoordinatesType;
    protected readonly taskCreatorForm = new FormGroup({
        data: new FormControl<Partial<TaskCreatorFormValues> | null>(null),
        area: new FormGroup({
            centerPointLatitude: new FormControl<number | null>(null, [Validators.required]),
            centerPointLongitude: new FormControl<number | null>(null, [Validators.required]),
            radius: new FormControl<number | null>(null, [Validators.required, Validators.min(AREA_RADIUS_MIN)]),
        }),
    });

    protected readonly isProcessing$ = this.localStore.selectByKey("isProcessing");
    protected readonly isPilotTaskApprovalRequired$ = this.localStore.selectByKey("isPilotTaskApprovalRequired");

    constructor(private readonly localStore: LocalComponentStore<TaskCreatorComponentState>) {
        this.localStore.setState({
            isProcessing: false,
            isPilotTaskApprovalRequired: false,
        });
    }

    public ngOnDestroy() {
        this.cleanUp.emit();
    }

    protected submit(): void {
        if (this.taskCreatorForm.invalid) {
            this.taskCreatorForm.markAllAsTouched();
            this.invalidFormScrollable.scrollToFirstInvalidField();

            return;
        }

        this.taskSubmit.emit(this.taskCreatorForm.value as TaskCreatorPilotFormValues);
    }
}
