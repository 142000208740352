export enum PanelType {
    ActionMarkers = "ActionMarkers",
    ActionMarkerCreator = "ActionMarkerCreator",
    ActionMarkerEdit = "ActionMarkerEdit",
    ActionMarkerIconChange = "ActionMarkerIconChange",
    ActionMarkerPreview = "ActionMarkerPreview",
    Areas = "Areas",
    Chat = "Chat",
    Tasks = "Tasks",
    TaskCreator = "TaskCreator",
    TaskPreview = "TaskPreview",
}

export type PanelOpenState = {
    [key in PanelType]: boolean;
};
