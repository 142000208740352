<div *ngIf="isExpanded$ | ngrxPush" @slideInSideOffScreenDown class="incident-panel">
    <section class="header">
        <button type="button" class="button-icon" (click)="closePanel()"><dtm-ui-icon name="close"></dtm-ui-icon></button>
        <h2 class="title">{{ "sahMobileLibIncident.panels.chatHeader" | transloco }}</h2>
    </section>

    <section class="content">
        <sah-shared-lib-incident-chat
            [messages]="messages$ | ngrxPush"
            [isProcessing]="isProcessing$ | ngrxPush"
            (messageSend)="sendMessage($event)"
        ></sah-shared-lib-incident-chat>
    </section>
</div>
