<ng-container *ngrxLet="{ isExpanded: isExpanded$, panelType: panelType$, tasks: tasks$ } as vm">
    <div *ngIf="vm.isExpanded" @slideInSideOffScreenDown class="incident-panel">
        <section class="header">
            <ng-container *ngIf="vm.panelType[PanelType.Tasks]">
                <button type="button" class="button-icon" (click)="closePanel()"><dtm-ui-icon name="close"></dtm-ui-icon></button>
                <h2 class="title">{{ "sahMobileLibIncident.panels.taskListHeader" | transloco }}</h2>
                <button type="button" class="button-primary" (click)="goToPanel(PanelType.TaskCreator)">
                    {{ "sahMobileLibIncident.taskList.addTaskButtonLabel" | transloco }}
                </button>
            </ng-container>

            <ng-container *ngIf="vm.panelType[PanelType.TaskCreator]">
                <button type="button" class="button-icon" (click)="goToPanel(PanelType.Tasks)">
                    <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                </button>
                <h2 class="title">{{ "sahMobileLibIncident.panels.taskCreatorHeader" | transloco }}</h2>
            </ng-container>

            <ng-container *ngIf="vm.panelType[PanelType.TaskPreview]">
                <button type="button" class="button-icon" (click)="goToPanel(PanelType.Tasks)">
                    <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                </button>
                <h2 class="title">{{ "sahMobileLibIncident.panels.taskPreviewHeader" | transloco }}</h2>
            </ng-container>
        </section>

        <section *ngIf="vm.panelType[PanelType.Tasks]" class="tasks-filters">
            <mat-slide-toggle labelPosition="before" [formControl]="showOnlyPilotTasksControl">
                <span class="toggle-label">{{ "sahMobileLibIncident.taskFilters.showOnlyPilotTasksLabel" | transloco }}</span>
            </mat-slide-toggle>
        </section>

        <section class="content scroll-shadows">
            <sah-mobile-lib-task-list
                *ngIf="vm.panelType[PanelType.Tasks]"
                [tasks]="vm.tasks"
                (taskPreview)="previewTask($event)"
            ></sah-mobile-lib-task-list>
            <sah-mobile-lib-task-creator
                *ngIf="vm.panelType[PanelType.TaskCreator]"
                [isProcessing]="isProcessing$ | ngrxPush"
                [isDefaultDateSet]="(incidentStatus$ | ngrxPush) === IncidentStatus.Active"
                [isPilotTaskApprovalRequired]="isPilotTaskApprovalRequired$ | ngrxPush"
                (taskSubmit)="createTaskWithArea($event)"
                (cancel)="goToPanel(PanelType.Tasks)"
                (cleanUp)="cancelTaskCreation()"
            ></sah-mobile-lib-task-creator>
            <sah-shared-lib-task-preview
                *ngIf="vm.panelType[PanelType.TaskPreview]"
                [task]="previewedTask$ | ngrxPush"
                (previewClose)="goToPanel(PanelType.Tasks)"
            ></sah-shared-lib-task-preview>
        </section>
    </div>
</ng-container>
