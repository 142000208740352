import { Injectable } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { FeatureGroup, Map, Point } from "leaflet";

const LeafletMarkerCluster = require("leaflet.markercluster");

export enum IncidentMapLayer {
    ActionMarkers = "ActionMarkers",
    Areas = "Areas",
    HandDrawings = "HandDrawings",
    TaskMarkers = "TaskMarkers",
}

const MARKER_ICON_HEIGHT = 30;
const MARKER_ICON_WIDTH = 240;

@UntilDestroy()
@Injectable()
export class IncidentMapLayersService {
    private map: Map | undefined;

    private readonly areasLayer = new FeatureGroup();
    private readonly actionMarkersLayer = new FeatureGroup();
    private readonly handDrawingsLayer = new FeatureGroup();
    private readonly taskMarkersLayer = new LeafletMarkerCluster.MarkerClusterGroup({
        spiderfyShapePositions: this.spiderfyMarkersPositions,
        animate: false,
    }) as FeatureGroup;

    public loadMapLayers(mapInstance: Map): void {
        this.map = mapInstance;

        this.map.addLayer(this.areasLayer);
        this.map.addLayer(this.taskMarkersLayer);
        this.map.addLayer(this.handDrawingsLayer);
        this.map.addLayer(this.actionMarkersLayer);
    }

    public getMapLayer(layer: IncidentMapLayer): FeatureGroup {
        switch (layer) {
            case IncidentMapLayer.ActionMarkers:
                return this.actionMarkersLayer;
            case IncidentMapLayer.Areas:
                return this.areasLayer;
            case IncidentMapLayer.HandDrawings:
                return this.handDrawingsLayer;
            case IncidentMapLayer.TaskMarkers:
                return this.taskMarkersLayer;
        }
    }

    private spiderfyMarkersPositions(markersNumber: number, centerPoint: Point): Point[] {
        const maximumNumberOfItemsPerColumn = 10;
        const numberOfColumns = Math.ceil(markersNumber / maximumNumberOfItemsPerColumn);

        const columnAndRowGap = 5;
        const markerHeightWithGap = MARKER_ICON_HEIGHT + columnAndRowGap;
        const markerWidthWithGap = MARKER_ICON_WIDTH + columnAndRowGap;
        const offsetXMarkerWidthDivider = 3;
        const offsetX = markerWidthWithGap / offsetXMarkerWidthDivider;

        const columnLength = markerHeightWithGap * maximumNumberOfItemsPerColumn;
        const columnStart = centerPoint.y - columnLength / 2;
        const markersPositions: Point[] = [];

        for (let columnNumber = 1; columnNumber <= numberOfColumns; columnNumber++) {
            let numberOfItemsInColumn = maximumNumberOfItemsPerColumn;
            const isLastColumn = columnNumber === numberOfColumns;

            if (isLastColumn) {
                numberOfItemsInColumn = markersNumber % maximumNumberOfItemsPerColumn || maximumNumberOfItemsPerColumn;
            }

            for (let columnItemNumber = 1; columnItemNumber <= numberOfItemsInColumn; columnItemNumber++) {
                markersPositions.push(
                    new Point(
                        centerPoint.x + markerWidthWithGap * columnNumber - offsetX,
                        columnStart + markerHeightWithGap * columnItemNumber
                    )
                );
            }
        }

        return markersPositions;
    }
}
