<ion-header>
    <ion-toolbar>
        <ion-buttons slot="start">
            <ion-menu-button></ion-menu-button>
        </ion-buttons>

        <div slot="start" class="logo desktop-menu">
            <a routerLink="/">
                <img ngSrc="assets/images/sah-mobile-logo.svg" alt="logo" [height]="LOGO_HEIGHT_PX" [width]="LOGO_WIDTH_PX" />
            </a>
        </div>
    </ion-toolbar>
</ion-header>
