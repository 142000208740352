import { NgOptimizedImage } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { BrowserModule, Title } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouteReuseStrategy } from "@angular/router";
import { DashboardModule, DASHBOARD_ENDPOINTS } from "@dtm-frontend/search-and-help-mobile-lib/dashboard";
import { IncidentModule, INCIDENT_ENDPOINTS } from "@dtm-frontend/search-and-help-mobile-lib/incident";
import { INCIDENT_SHARED_ENDPOINTS } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { SearchAndHelpSharedModule } from "@dtm-frontend/search-and-help-shared-lib/shared";
import { KEYCLOAK_CONFIG } from "@dtm-frontend/shared/auth";
import { AZURE_MAPS_SUBSCRIPTION_KEY, CapacitorGeolocationService, GeolocationService } from "@dtm-frontend/shared/map";
import { LEAFLET_MAP_CONFIG } from "@dtm-frontend/shared/map/leaflet";
import { SharedUiModule } from "@dtm-frontend/shared/ui";
import {
    getTranslocoInlineLoader,
    I18nRootModule,
    LanguageCode,
    LANGUAGE_CONFIGURATION,
    TranslationHelperService,
} from "@dtm-frontend/shared/ui/i18n";
import { SharedToastModule } from "@dtm-frontend/shared/ui/toast";
import { Logger, LoggerModule } from "@dtm-frontend/shared/utils";
import { SharedWebsocketModule, SharedWebsocketModuleConfig, WEBSOCKET_CONFIGURATION } from "@dtm-frontend/shared/websocket";
import { IonicModule, IonicRouteStrategy } from "@ionic/angular";
import { TRANSLOCO_SCOPE } from "@ngneat/transloco";
import { LetModule, PushModule } from "@ngrx/component";
import { NgxsModule } from "@ngxs/store";
import { first, tap } from "rxjs/operators";
import { SearchAndHelpMobileEnvironment } from "../environments/environment.model";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/header/header.component";
import { MenuComponent } from "./components/menu/menu.component";

Logger.initialize("https://e2e24872c49e9ae00ede114c7f8a1bea@sentry.pansa.cloud/16");

export function createAppModule(environment: SearchAndHelpMobileEnvironment) {
    @NgModule({
        imports: [
            NgxsModule.forRoot([], {
                // NOTE: must be first because of https://github.com/ngxs/store/issues/375
                developmentMode: !environment.production,
            }),
            AppRoutingModule,
            BrowserAnimationsModule,
            BrowserModule,
            DashboardModule,
            HttpClientModule,
            I18nRootModule.forRoot({
                developmentMode: !environment.production,
                ...LANGUAGE_CONFIGURATION,
            }),
            IncidentModule,
            IonicModule.forRoot(),
            LetModule,
            LoggerModule.forRoot(environment.name, !environment.production),
            NgOptimizedImage,
            PushModule,
            SearchAndHelpSharedModule,
            SharedToastModule.forRoot({ positionClass: "toast-top-center" }),
            SharedUiModule,
            SharedWebsocketModule.forRoot(),
        ],
        declarations: [AppComponent, HeaderComponent, MenuComponent],
        providers: [
            { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
            {
                provide: TRANSLOCO_SCOPE,
                multi: true,
                useValue: {
                    scope: "sahMobile",
                    loader: getTranslocoInlineLoader((language: LanguageCode) => import(`../assets/i18n/${language}.json`)),
                },
            },
            {
                provide: AZURE_MAPS_SUBSCRIPTION_KEY,
                useValue: environment.azureMapsSubscriptionKey,
            },
            {
                provide: LEAFLET_MAP_CONFIG,
                useValue: environment.leafletMapConfig,
            },
            {
                provide: KEYCLOAK_CONFIG,
                useValue: environment.keycloakConfig,
            },
            {
                provide: DASHBOARD_ENDPOINTS,
                useValue: environment.dashboardEndpoints,
            },
            {
                provide: INCIDENT_ENDPOINTS,
                useValue: environment.incidentEndpoints,
            },
            {
                provide: INCIDENT_SHARED_ENDPOINTS,
                useValue: environment.incidentSharedEndpoints,
            },
            {
                provide: WEBSOCKET_CONFIGURATION,
                useFactory: (): SharedWebsocketModuleConfig => ({
                    endpoint: environment.websocketEndpoint,
                }),
            },
            {
                provide: GeolocationService,
                useClass: CapacitorGeolocationService,
            },
        ],
        bootstrap: [AppComponent],
    })
    class AppModule {
        constructor(translocoHelper: TranslationHelperService, titleService: Title) {
            const gotTitle$ = translocoHelper
                .waitForTranslation("sahMobile.appTitle")
                .pipe(first(), tap(titleService.setTitle.bind(titleService)));

            gotTitle$
                .pipe(
                    first(),
                    tap(() => {
                        document.getElementById("loader")?.remove();
                    })
                )
                .subscribe();
        }
    }

    return AppModule;
}
