import { HttpStatusCode } from "@angular/common/http";
import {
    IncidentError,
    IncidentErrorType,
    TaskCreatorFormValues,
    TaskCreatorPilotFormValues,
} from "@dtm-frontend/search-and-help-shared-lib/incident";
import { HttpErrorResponseBody } from "@dtm-frontend/shared/utils";
import { GeoJSON } from "leaflet";

const areaOutOfIncidentBoundaryErrorKey = "sah.area.validation.not_intersectional_with_incident";

interface CreateOrUpdateTaskRequestPayload {
    name: string | null;
    altitudeRange: {
        floor: number | null;
        ceiling: number | null;
    };
    timeRange: {
        start: Date | null;
        stop: Date | null;
    };
    notes: string | null;
}

interface CreateAreaPayload {
    center: GeoJSON.Point;
    radius: number;
}

export function convertTaskCreatorFormValuesToCreateOrUpdateTaskRequestPayload({
    name,
    floorAltitude,
    ceilingAltitude,
    startTime,
    endTime,
    details,
}: TaskCreatorFormValues): CreateOrUpdateTaskRequestPayload {
    return {
        name,
        altitudeRange: {
            floor: floorAltitude,
            ceiling: ceilingAltitude,
        },
        timeRange: {
            start: startTime,
            stop: endTime,
        },
        notes: details,
    };
}

export function convertTaskCreatorPilotFormValuesToCreateAreaPayload(form: TaskCreatorPilotFormValues): CreateAreaPayload {
    return {
        center: { type: "Point", coordinates: [form.area.centerPointLongitude, form.area.centerPointLatitude] },
        radius: form.area.radius,
    };
}

export function convertCreateAreaErrorToIncidentError({ error }: HttpErrorResponseBody): IncidentError {
    if (error.status === HttpStatusCode.BadRequest && error.generalMessage === areaOutOfIncidentBoundaryErrorKey) {
        return { type: IncidentErrorType.AreaOutOfIncidentBoundary, args: {}, messageKey: "" };
    }

    return { type: IncidentErrorType.Unknown, args: {}, messageKey: "" };
}
