import { MapToolName, Task, TaskCreatorPilotFormValues } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { PanelType } from "../models/incident.models";

export namespace IncidentActions {
    export class ResetState {
        public static readonly type = "[Incident] Reset state";
    }

    export class OpenPanel {
        public static readonly type = "[Incident] Open panel";
        constructor(public panelViewType: PanelType) {}
    }

    export class ClosePanel {
        public static readonly type = "[Incident] Close panel";
    }

    export class CreateTaskWithArea {
        public static readonly type = "[Incident] Create task with area";
        constructor(public form: TaskCreatorPilotFormValues) {}
    }

    export class RemoveTaskCreationData {
        public static readonly type = "[Incident] Remove task creation data";
    }

    export class OpenTaskPreview {
        public static readonly type = "[Incident] Open task preview";
        constructor(public task: Task) {}
    }

    export class SelectMapTool {
        public static readonly type = "[Incident] Select map tool";
        constructor(public mapToolName: MapToolName) {}
    }
}
