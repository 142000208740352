<form [formGroup]="taskCreatorForm">
    <sah-shared-lib-task-creator-form
        dtmUiMarkValueAccessorControlsAsTouched
        dtmUiInvalidFormScrollable
        formControlName="data"
    ></sah-shared-lib-task-creator-form>

    <section formGroupName="area">
        <dtm-ui-dms-coordinates-input
            dtmUiMarkValueAccessorControlsAsTouched
            formControlName="centerPointLatitude"
            [geographicCoordinatesType]="GeographicCoordinatesType.Latitude"
            [inputLabel]="'sahMobileLibIncident.taskCreator.areaCoordinatesLabel' | transloco"
        >
            <div class="field-error" *dtmUiFieldHasError="taskCreatorForm.controls.area.controls.centerPointLatitude; name: 'required'">
                {{ "sahMobileLibIncident.taskCreator.fieldRequiredError" | transloco }}
            </div>
        </dtm-ui-dms-coordinates-input>
        <dtm-ui-dms-coordinates-input
            dtmUiMarkValueAccessorControlsAsTouched
            formControlName="centerPointLongitude"
            [geographicCoordinatesType]="GeographicCoordinatesType.Longitude"
        >
            <div class="field-error" *dtmUiFieldHasError="taskCreatorForm.controls.area.controls.centerPointLongitude; name: 'required'">
                {{ "sahMobileLibIncident.taskCreator.fieldRequiredError" | transloco }}
            </div>
        </dtm-ui-dms-coordinates-input>
        <dtm-ui-expanded-number-input
            formControlName="radius"
            [valueSuffix]="'sahMobileLibIncident.taskCreator.altitudeSuffix' | transloco"
        >
            <label>{{ "sahMobileLibIncident.taskCreator.radiusLabel" | transloco }}</label>
            <div class="field-error" *dtmUiFieldHasError="taskCreatorForm.controls.area.controls.radius; name: 'required'">
                {{ "sahMobileLibIncident.taskCreator.fieldRequiredError" | transloco }}
            </div>
            <div class="field-error" *dtmUiFieldHasError="taskCreatorForm.controls.area.controls.radius; name: 'min'; error as error">
                {{
                    "sahMobileLibIncident.taskCreator.minValueError"
                        | transloco
                            : {
                                  min: error.min,
                                  unit: "sahMobileLibIncident.taskCreator.altitudeSuffix" | transloco
                              }
                }}
            </div>
        </dtm-ui-expanded-number-input>
    </section>

    <div class="actions" *ngrxLet="isProcessing$ as isProcessing">
        <button type="button" class="button-secondary" [disabled]="isProcessing" (click)="cancel.emit()">
            {{ "sahMobileLibIncident.taskCreator.cancelButtonLabel" | transloco }}
        </button>
        <button type="button" class="button-primary" [disabled]="isProcessing" (click)="submit()">
            <mat-spinner *ngIf="isProcessing" diameter="20" color="accent"></mat-spinner>
            <span>{{
                ((isPilotTaskApprovalRequired$ | ngrxPush)
                    ? "sahMobileLibIncident.taskCreator.submitButtonRequiredAcceptanceLabel"
                    : "sahMobileLibIncident.taskCreator.submitButtonLabel"
                ) | transloco
            }}</span>
        </button>
    </div>
</form>
