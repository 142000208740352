import { ChangeDetectionStrategy, Component, EventEmitter, Output } from "@angular/core";

@Component({
    selector: "sah-mobile-lib-incident-navbar",
    templateUrl: "incident-navbar.component.html",
    styleUrls: ["incident-navbar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IncidentNavbarComponent {
    @Output() protected readonly markersSelect = new EventEmitter();
    @Output() protected readonly areasSelect = new EventEmitter();
    @Output() protected readonly tasksSelect = new EventEmitter();
}
