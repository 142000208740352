import { ChangeDetectionStrategy, Component } from "@angular/core";

const LOGO_HEIGHT_PX = 43;
const LOGO_WIDTH_PX = 107;
@Component({
    selector: "sah-mobile-header",
    templateUrl: "header.component.html",
    styleUrls: ["header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent {
    protected readonly LOGO_HEIGHT_PX = LOGO_HEIGHT_PX;
    protected readonly LOGO_WIDTH_PX = LOGO_WIDTH_PX;
}
