import { ChangeDetectionStrategy, Component } from "@angular/core";
import { AuthState } from "@dtm-frontend/shared/auth";
import { Store } from "@ngxs/store";

@Component({
    selector: "sah-mobile-root",
    templateUrl: "app.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent {
    protected readonly isLoggedIn$ = this.store.select(AuthState.isLoggedIn);

    constructor(private readonly store: Store) {}
}
