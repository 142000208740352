import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";

export namespace DashboardActions {
    export class GetMyActiveIncidents {
        public static readonly type = "[Dashboard] Get my active incidents";
        constructor(public pageEvent?: PageEvent) {}
    }

    export class GetMyPlannedIncidents {
        public static readonly type = "[Dashboard] Get my planned incidents";
        constructor(public pageEvent?: PageEvent) {}
    }

    export class GetMyCompletedIncidents {
        public static readonly type = "[Dashboard] Get my completed incidents";
        constructor(public pageEvent?: PageEvent) {}
    }
}
