import { ChangeDetectionStrategy, Component, OnDestroy, ViewChild } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import {
    ActionMarkerService,
    AreaService,
    HandDrawingService,
    IncidentMapLayersService,
    IncidentSharedDataActions,
    IncidentSharedDataState,
    IncidentStatus,
    INCIDENT_AREA_PATH_OPTIONS,
    MapToolName,
    SahMapUtils,
    TaskMarkerService,
} from "@dtm-frontend/search-and-help-shared-lib/incident";
import { LeafletMapComponent } from "@dtm-frontend/shared/map/leaflet";
import { UIState } from "@dtm-frontend/shared/ui";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import "@geoman-io/leaflet-geoman-free";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { Map } from "leaflet";
import { withLatestFrom } from "rxjs";
import { map } from "rxjs/operators";
import { SahMobileAbsolutePath } from "../../../shared/defaults/paths";
import { PanelType } from "../../models/incident.models";
import { IncidentActions } from "../../state/incident.actions";
import { IncidentState } from "../../state/incident.state";

@UntilDestroy()
@Component({
    selector: "sah-mobile-lib-active-incident",
    templateUrl: "active-incident.component.html",
    styleUrls: ["../../../shared/styles/incident.scss", "active-incident.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [AreaService, ActionMarkerService, HandDrawingService, IncidentMapLayersService, TaskMarkerService],
})
export class ActiveIncidentComponent implements OnDestroy {
    @ViewChild(LeafletMapComponent) private leafletMapComponent: LeafletMapComponent | undefined;

    protected readonly SahMobileAbsolutePath = SahMobileAbsolutePath;
    protected readonly MapToolName = MapToolName;
    protected readonly PanelType = PanelType;

    protected readonly incidentArea$ = this.store.select(IncidentSharedDataState.incidentArea);
    protected readonly isIncidentValid$ = this.store
        .select(IncidentSharedDataState.incidentStatus)
        .pipe(map((status) => status === IncidentStatus.Active));
    protected readonly isIncidentMapDataProcessing$ = this.store.select(IncidentSharedDataState.isProcessing);
    protected readonly isSelectionEnabled$ = this.store.select(IncidentState.isSelectionEnabled);
    protected readonly selectMapToolName$ = this.store.select(IncidentState.selectedMapToolName);

    protected readonly actionMarkers$ = this.store.select(IncidentSharedDataState.actionMarkers);
    protected readonly isActionMarkerCreationEnabled$ = this.store.select(IncidentState.isActionMarkerCreationEnabled);

    constructor(
        private readonly areaService: AreaService,
        private readonly handDrawingService: HandDrawingService,
        private readonly mapLayersService: IncidentMapLayersService,
        private readonly route: ActivatedRoute,
        private readonly store: Store,
        private readonly taskMarkerService: TaskMarkerService,
        protected readonly actionMarkerService: ActionMarkerService
    ) {
        const incidentId = this.route.snapshot.params?.id;
        this.store.dispatch([
            new IncidentSharedDataActions.GetOperationalSituation(incidentId),
            new IncidentSharedDataActions.StartIncidentUpdatesWatch(incidentId),
        ]);

        this.initMap();
        this.watchLanguageChangeAndUpdateGeoman();
    }

    public ngOnDestroy() {
        this.store.dispatch([
            IncidentSharedDataActions.StopIncidentUpdatesWatch,
            IncidentSharedDataActions.ResetState,
            IncidentActions.ResetState,
        ]);
    }

    protected openPanel(panelType: PanelType): void {
        this.store.dispatch(new IncidentActions.OpenPanel(panelType));
    }

    private initMap(): void {
        this.incidentArea$
            .pipe(
                RxjsUtils.filterFalsy(),
                withLatestFrom(
                    this.isIncidentValid$,
                    this.store.select(IncidentSharedDataState.areas),
                    this.store.select(IncidentSharedDataState.handDrawings),
                    this.store.select(IncidentSharedDataState.tasks)
                ),
                untilDestroyed(this)
            )
            .subscribe(([incidentArea, isIncidentValid, areas, handDrawings, tasks]) => {
                if (!isIncidentValid) {
                    return;
                }

                this.leafletMapComponent?.getMap().then((mapInstance: Map) => {
                    mapInstance.pm.setLang(this.store.selectSnapshot(UIState.activeLanguage));
                    this.mapLayersService.loadMapLayers(mapInstance);

                    if (incidentArea) {
                        incidentArea = SahMapUtils.createMutableMapArea(incidentArea);

                        mapInstance.addLayer(incidentArea);
                        incidentArea.setStyle(INCIDENT_AREA_PATH_OPTIONS);
                        mapInstance.fitBounds(incidentArea.getBounds());
                    }

                    this.actionMarkerService.initMap(mapInstance);
                    this.areaService.initMapWithAreas(mapInstance, areas, tasks);
                    this.taskMarkerService.initMapWithTaskMarkers(mapInstance, areas, tasks);
                    this.handDrawingService.initMapWithHandDrawings(mapInstance, handDrawings);
                });
            });
    }

    private watchLanguageChangeAndUpdateGeoman(): void {
        this.store
            .select(UIState.activeLanguage)
            .pipe(untilDestroyed(this))
            .subscribe((language) => this.leafletMapComponent?.getMap().then((mapInstance) => mapInstance.pm.setLang(language)));
    }
}
