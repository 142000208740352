<ng-container
    *ngrxLet="{
        isExpanded: isExpanded$,
        panelType: panelType$,
        selectedActionMarkerData: selectedActionMarkerData$,
        isProcessing: isProcessing$
    } as vm"
>
    <div *ngIf="vm.isExpanded" @slideInSideOffScreenDown class="incident-panel">
        <dtm-ui-loader-container [isShown]="vm.isProcessing">
            <section class="header">
                <ng-container *ngIf="vm.panelType[PanelType.ActionMarkers] || vm.panelType[PanelType.ActionMarkerCreator]">
                    <button type="button" class="button-icon" (click)="closePanel(); clearMarkerSelection()">
                        <dtm-ui-icon name="close"></dtm-ui-icon>
                    </button>
                    <h2 class="title">{{ "sahMobileLibIncident.panels.actionMarkers" | transloco }}</h2>
                </ng-container>

                <ng-container *ngIf="vm.panelType[PanelType.ActionMarkerPreview]">
                    <button type="button" class="button-icon" (click)="closePanel(); clearMarkerSelection()">
                        <dtm-ui-icon name="close"></dtm-ui-icon>
                    </button>
                    <h2 class="title">{{ "sahMobileLibIncident.actionMarkers.previewHeader" | transloco }}</h2>
                </ng-container>

                <ng-container *ngIf="vm.panelType[PanelType.ActionMarkerEdit]">
                    <button type="button" class="button-icon" (click)="openPanel(PanelType.ActionMarkerPreview)">
                        <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                    </button>
                    <h2 class="title">{{ "sahMobileLibIncident.actionMarkers.editHeader" | transloco }}</h2>
                </ng-container>

                <ng-container *ngIf="vm.panelType[PanelType.ActionMarkerIconChange]">
                    <button type="button" class="button-icon" (click)="openPanel(PanelType.ActionMarkerPreview)">
                        <dtm-ui-icon name="arrow-go-back"></dtm-ui-icon>
                    </button>
                    <h2 class="title">{{ "sahMobileLibIncident.actionMarkers.changeIconHeader" | transloco }}</h2>
                </ng-container>
            </section>

            <section class="content scroll-shadows">
                <sah-shared-lib-action-markers-select-list
                    *ngIf="vm.panelType[PanelType.ActionMarkers]"
                    (markerSelect)="selectMarkerToCreate($event)"
                ></sah-shared-lib-action-markers-select-list>

                <sah-shared-lib-action-marker-editor
                    *ngIf="vm.panelType[PanelType.ActionMarkerCreator]"
                    [markerData]="vm.selectedActionMarkerData"
                    (editCancel)="clearMarkerSelection()"
                    (dataSubmit)="addMarker($event)"
                ></sah-shared-lib-action-marker-editor>

                <sah-shared-lib-action-marker-preview
                    *ngIf="vm.panelType[PanelType.ActionMarkerPreview]"
                    [markerData]="vm.selectedActionMarkerData"
                    [isReadonly]="!vm.selectedActionMarkerData?.isMine"
                    (iconChange)="openPanel(PanelType.ActionMarkerIconChange)"
                    (dragToggle)="actionMarkerService.toggleSelectedMarkerDragging($event); toggleDragActionMarkerToast($event)"
                    (edit)="openPanel(PanelType.ActionMarkerEdit)"
                    (remove)="actionMarkerService.removeActionMarker(vm.selectedActionMarkerData?.id)"
                ></sah-shared-lib-action-marker-preview>

                <sah-shared-lib-action-marker-editor
                    *ngIf="vm.panelType[PanelType.ActionMarkerEdit]"
                    [markerData]="vm.selectedActionMarkerData"
                    (editCancel)="openPanel(PanelType.ActionMarkerPreview)"
                    (dataSubmit)="updateMarker($event)"
                ></sah-shared-lib-action-marker-editor>

                <sah-shared-lib-action-markers-select-list
                    *ngIf="vm.panelType[PanelType.ActionMarkerIconChange]"
                    [selectedActionMarker]="vm.selectedActionMarkerData?.tool?.name"
                    (markerSelect)="updateMarkerIcon($event)"
                ></sah-shared-lib-action-markers-select-list>
            </section>
        </dtm-ui-loader-container>
    </div>
</ng-container>
