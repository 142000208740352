import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { IncidentList, IncidentStatus } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { MIN_PAGE_SIZE_VALUE } from "@dtm-frontend/shared/ui";
import { catchError, map, Observable, throwError } from "rxjs";
import { DashboardEndpoints, DASHBOARD_ENDPOINTS } from "../dashboard.tokens";
import { DashboardErrorType } from "../models/dashboard.models";
import { convertGetIncidentsResponseBodyToIncidentList, GetIncidentsResponseBody } from "./dashboard-api.converters";

@Injectable()
export class DashboardApiService {
    constructor(@Inject(DASHBOARD_ENDPOINTS) private readonly endpoints: DashboardEndpoints, private readonly http: HttpClient) {}

    public getMyActiveIncidents(page?: PageEvent): Observable<IncidentList> {
        return this.http
            .get<GetIncidentsResponseBody>(this.endpoints.getIncidents, {
                params: {
                    status: IncidentStatus.Active,
                    page: page?.pageIndex ?? 0,
                    size: page?.pageSize ?? MIN_PAGE_SIZE_VALUE,
                },
            })
            .pipe(
                map((incidents) => convertGetIncidentsResponseBodyToIncidentList(incidents)),
                catchError(() => throwError(() => ({ type: DashboardErrorType.Unknown })))
            );
    }

    public getMyPlannedIncidents(page?: PageEvent): Observable<IncidentList> {
        return this.http
            .get<GetIncidentsResponseBody>(this.endpoints.getIncidents, {
                params: {
                    status: IncidentStatus.Planned,
                    page: page?.pageIndex ?? 0,
                    size: page?.pageSize ?? MIN_PAGE_SIZE_VALUE,
                },
            })
            .pipe(
                map((incidents) => convertGetIncidentsResponseBodyToIncidentList(incidents)),
                catchError(() => throwError(() => ({ type: DashboardErrorType.Unknown })))
            );
    }

    public getMyCompletedIncidents(page?: PageEvent): Observable<IncidentList> {
        return this.http
            .get<GetIncidentsResponseBody>(this.endpoints.getIncidents, {
                params: {
                    status: IncidentStatus.Completed,
                    page: page?.pageIndex ?? 0,
                    size: page?.pageSize ?? MIN_PAGE_SIZE_VALUE,
                },
            })
            .pipe(
                map((incidents) => convertGetIncidentsResponseBodyToIncidentList(incidents)),
                catchError(() => throwError(() => ({ type: DashboardErrorType.Unknown })))
            );
    }
}
