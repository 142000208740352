import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";
import { MapArea } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { EmptyStateMode } from "@dtm-frontend/shared/ui";
import { LocalComponentStore } from "@dtm-frontend/shared/utils";

interface AreaListComponentState {
    areas: MapArea[];
}

@Component({
    selector: "sah-mobile-lib-area-list",
    templateUrl: "area-list.component.html",
    styleUrls: ["area-list.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [LocalComponentStore],
})
export class AreaListComponent {
    @Input() public set areas(value: MapArea[] | undefined) {
        this.localStore.patchState({ areas: value });
    }

    @Output() protected readonly areaShow = new EventEmitter<MapArea>();

    protected readonly EmptyStateMode = EmptyStateMode;

    protected readonly areas$ = this.localStore.selectByKey("areas");

    constructor(private readonly localStore: LocalComponentStore<AreaListComponentState>) {
        this.localStore.setState({ areas: [] });
    }
}
