<ng-container *ngrxLet="{ isExpanded: isExpanded$, panelType: panelType$, areas: areas$ } as vm">
    <div *ngIf="vm.isExpanded" @slideInSideOffScreenDown class="incident-panel">
        <section class="header">
            <ng-container *ngIf="vm.panelType[PanelType.Areas]">
                <button type="button" class="button-icon" (click)="closePanel()"><dtm-ui-icon name="close"></dtm-ui-icon></button>
                <h2 class="title">{{ "sahMobileLibIncident.panels.areasHeader" | transloco }}</h2>
            </ng-container>
        </section>
        <section class="content scroll-shadows">
            <ng-container *ngIf="vm.panelType[PanelType.Areas]">
                <sah-mobile-lib-area-list [areas]="vm.areas" (areaShow)="showAreaOnMap($event)"></sah-mobile-lib-area-list>
            </ng-container>
        </section>
    </div>
</ng-container>
