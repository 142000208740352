import { Injectable } from "@angular/core";
import { RxjsUtils } from "@dtm-frontend/shared/utils";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { Store } from "@ngxs/store";
import { Canvas, Map } from "leaflet";
import { IncidentSharedDataState, SahMapUtils } from "../index";
import { CustomLeafletMapEvent, HandDrawingPolyline } from "../models/incident-map.models";
import { IncidentMapLayer, IncidentMapLayersService } from "./incident-map-layers.service";

const CLICK_TOLERANCE_PX = 10;

@UntilDestroy()
@Injectable()
export class HandDrawingService {
    private readonly HAND_DRAW_RENDERER = new Canvas({ tolerance: CLICK_TOLERANCE_PX });
    private map: Map | undefined;

    constructor(private readonly mapLayersService: IncidentMapLayersService, private readonly store: Store) {}

    private get layer() {
        return this.mapLayersService.getMapLayer(IncidentMapLayer.HandDrawings);
    }

    public initMapWithHandDrawings(map: Map, handDrawings: HandDrawingPolyline[]): void {
        this.map = map;
        this.initHandDrawings(handDrawings);

        this.watchHandDrawingCreate();
        this.watchHandDrawingRemove();
    }

    private initHandDrawings(handDrawings: HandDrawingPolyline[]): void {
        handDrawings.forEach((handDrawing) => this.addHandDrawingToMap(handDrawing));
    }

    private addHandDrawingToMap(drawing: HandDrawingPolyline): void {
        const mapHandDrawing = SahMapUtils.createMutableHandDrawPolyline(drawing, this.HAND_DRAW_RENDERER);

        this.layer.addLayer(mapHandDrawing);
        this.map?.fire(CustomLeafletMapEvent.LoadHandDrawing, { drawing: mapHandDrawing });
    }

    private getHandDrawingFromMapLayer(handDrawingId: string | undefined): HandDrawingPolyline | undefined {
        return (this.layer.getLayers() as HandDrawingPolyline[]).find((layer) => layer.data?.id === handDrawingId);
    }

    private watchHandDrawingCreate(): void {
        this.store
            .select(IncidentSharedDataState.createdHandDrawing)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((handDrawing) => {
                if (!handDrawing) {
                    return;
                }

                this.addHandDrawingToMap(handDrawing);
            });
    }

    private watchHandDrawingRemove(): void {
        this.store
            .select(IncidentSharedDataState.removedHandDrawingId)
            .pipe(RxjsUtils.filterFalsy(), untilDestroyed(this))
            .subscribe((removedHandDrawingId) => {
                const handDrawingToRemove = this.getHandDrawingFromMapLayer(removedHandDrawingId);
                if (!handDrawingToRemove) {
                    return;
                }

                this.layer.removeLayer(handDrawingToRemove);
                this.map?.fire(CustomLeafletMapEvent.ClearHandDrawingSelection);
            });
    }
}
