import { createDynamicEnvironment } from "@dtm-frontend/shared/utils";
import type DynamicConfiguation from "../assets/dynamic.config.json";
import { SearchAndHelpMobileEnvironment } from "./environment.model";

export const dynamicEnvironment = createDynamicEnvironment<SearchAndHelpMobileEnvironment, typeof DynamicConfiguation>(
    fetch("../assets/dynamic.config.json").then((config) => config.json()),
    ({ apiDomain, keycloakConfig }) => {
        const apiUrl = `https://${apiDomain}/api`;
        const appSpecificApiUrl = `${apiUrl}/search-and-help`;
        const websocketEndpoint = `wss://${apiDomain}/api/ws`;

        return {
            production: false,
            name: "dev",
            keycloakConfig,
            azureMapsSubscriptionKey: "HS9JYfhTrM0Ud2smxePEEpkueCl81_SAD2T-VU-ZNFk",
            bearerExcludedUrls: [],
            leafletMapConfig: {
                defaultPosition: {
                    lat: 52.115,
                    lng: 19.424,
                },
                zoom: {
                    min: 6,
                    max: 18,
                    initial: 7,
                    userPosition: 17,
                },
            },
            websocketEndpoint,
            dashboardEndpoints: {
                getIncidents: `${appSpecificApiUrl}/incidents`,
            },
            incidentSharedEndpoints: {
                getIncidentMapData: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/team-situation`,
                createActionMarker: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/markers`,
                updateActionMarker: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/markers/{{markerId}}`,
                addActionMarkerPhoto: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/markers/{{markerId}}/photo`,
                removeActionMarkerPhoto: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/markers/{{markerId}}/photo`,
                getActionMarkerPhoto: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/markers/{{markerId}}/photo`,
                removeActionMarker: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/markers/{{markerId}}`,
                sendIncidentMessage: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/comments`,
                wsIncidentTopic: "/websocket/topic/search-and-help/incident-id/{{incidentId}}",
            },
            incidentEndpoints: {
                createTask: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/team-situations/{{teamId}}/tasks`,
                createArea: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/areas`,
                assignAreaToTask: `${appSpecificApiUrl}/operational-situations/{{incidentId}}/tasks/{{taskId}}/area-assignments`,
            },
        };
    }
);
