export enum SahMobilePath {
    Dashboard = "dashboard",
    Login = "login",
    ActiveIncident = "incidents/active/:id",
}

export const SahMobileAbsolutePath = Object.keys(SahMobilePath).reduce(
    (result, key) => ({
        ...result,
        [key]: `/${SahMobilePath[key as keyof typeof SahMobilePath]}`,
    }),
    {}
) as Record<keyof typeof SahMobilePath, string>;
