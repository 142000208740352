<ng-container *ngrxLet="areas$ as areas">
    <div *ngIf="areas.length; else emptyListTemplate" class="area-list">
        <sah-shared-lib-area-card *ngFor="let area of areas" [area]="area">
            <ng-container actions>
                <button type="button" class="button-icon" (click)="areaShow.emit(area)">
                    <dtm-ui-icon name="road-map"></dtm-ui-icon>
                </button>
            </ng-container>
        </sah-shared-lib-area-card>
    </div>

    <ng-template #emptyListTemplate>
        <dtm-ui-empty-state [mode]="EmptyStateMode.Tertiary">
            <img imageSlot src="assets/images/no-results.svg" alt="" />
            <ng-container messageSlot>
                {{ "sahMobileLibIncident.areaList.emptyAreaListMessage" | transloco }}
            </ng-container>
        </dtm-ui-empty-state>
    </ng-template>
</ng-container>
