import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import {
    IncidentErrorType,
    TaskCreatorFormValues,
    TaskCreatorPilotFormValues,
    TaskErrorType,
} from "@dtm-frontend/search-and-help-shared-lib/incident";
import { StringUtils } from "@dtm-frontend/shared/utils";
import { catchError, Observable, throwError } from "rxjs";
import { IncidentEndpoints, INCIDENT_ENDPOINTS } from "../tokens";
import {
    convertCreateAreaErrorToIncidentError,
    convertTaskCreatorFormValuesToCreateOrUpdateTaskRequestPayload,
    convertTaskCreatorPilotFormValuesToCreateAreaPayload,
} from "./incident-api.converters";

@Injectable()
export class IncidentApiService {
    constructor(@Inject(INCIDENT_ENDPOINTS) private readonly endpoints: IncidentEndpoints, private readonly http: HttpClient) {}

    public createTask(incidentId: string, teamId: string, formValues: TaskCreatorFormValues): Observable<{ id: string }> {
        return this.http
            .post<{ id: string }>(
                StringUtils.replaceInTemplate(this.endpoints.createTask, { incidentId, teamId }),
                convertTaskCreatorFormValuesToCreateOrUpdateTaskRequestPayload(formValues)
            )
            .pipe(catchError(() => throwError(() => ({ type: TaskErrorType.Unknown }))));
    }

    public createArea(incidentId: string, form: TaskCreatorPilotFormValues): Observable<{ id: string }> {
        return this.http
            .post<{ id: string }>(
                StringUtils.replaceInTemplate(this.endpoints.createArea, { incidentId }),
                convertTaskCreatorPilotFormValuesToCreateAreaPayload(form)
            )
            .pipe(catchError((error) => throwError(() => convertCreateAreaErrorToIncidentError(error))));
    }

    public assignAreaToTask(incidentId: string, taskId: string, areaId: string): Observable<void> {
        return this.http
            .post<void>(StringUtils.replaceInTemplate(this.endpoints.assignAreaToTask, { incidentId, taskId }), {
                areaId,
            })
            .pipe(catchError(() => throwError(() => ({ type: IncidentErrorType.Unknown }))));
    }
}
