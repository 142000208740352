import { Injectable } from "@angular/core";
import { IncidentListItem } from "@dtm-frontend/search-and-help-shared-lib/incident";
import { Page } from "@dtm-frontend/shared/ui";
import { Action, Selector, State, StateContext } from "@ngxs/store";
import { catchError, EMPTY, finalize, tap } from "rxjs";
import { DashboardError } from "../models/dashboard.models";
import { DashboardApiService } from "../services/dashboard-api.service";
import { DashboardActions } from "./dashboard.actions";

interface DashboardStateModel {
    isProcessing: boolean;
    myActiveIncidents: IncidentListItem[] | undefined;
    myActiveIncidentsPage: Page | undefined;
    myActiveIncidentsError: DashboardError | undefined;
    myPlannedIncidents: IncidentListItem[] | undefined;
    myPlannedIncidentsPage: Page | undefined;
    myPlannedIncidentsError: DashboardError | undefined;
    myCompletedIncidents: IncidentListItem[] | undefined;
    myCompletedIncidentsPage: Page | undefined;
    myCompletedIncidentsError: DashboardError | undefined;
}

const DEFAULT_STATE: DashboardStateModel = {
    isProcessing: false,
    myActiveIncidents: undefined,
    myActiveIncidentsPage: undefined,
    myActiveIncidentsError: undefined,
    myPlannedIncidents: undefined,
    myPlannedIncidentsPage: undefined,
    myPlannedIncidentsError: undefined,
    myCompletedIncidents: undefined,
    myCompletedIncidentsPage: undefined,
    myCompletedIncidentsError: undefined,
};

@State<DashboardStateModel>({
    name: "dashboard",
    defaults: DEFAULT_STATE,
})
@Injectable()
export class DashboardState {
    @Selector()
    public static isProcessing(state: DashboardStateModel): boolean {
        return state.isProcessing;
    }

    @Selector()
    public static myActiveIncidents(state: DashboardStateModel): IncidentListItem[] | undefined {
        return state.myActiveIncidents;
    }

    @Selector()
    public static myActiveIncidentsPage(state: DashboardStateModel): Page | undefined {
        return state.myActiveIncidentsPage;
    }

    @Selector()
    public static myActiveIncidentsError(state: DashboardStateModel): DashboardError | undefined {
        return state.myActiveIncidentsError;
    }

    @Selector()
    public static myPlannedIncidents(state: DashboardStateModel): IncidentListItem[] | undefined {
        return state.myPlannedIncidents;
    }

    @Selector()
    public static myPlannedIncidentsPage(state: DashboardStateModel): Page | undefined {
        return state.myPlannedIncidentsPage;
    }

    @Selector()
    public static myPlannedIncidentsError(state: DashboardStateModel): DashboardError | undefined {
        return state.myPlannedIncidentsError;
    }

    @Selector()
    public static myCompletedIncidents(state: DashboardStateModel): IncidentListItem[] | undefined {
        return state.myCompletedIncidents;
    }

    @Selector()
    public static myCompletedIncidentsPage(state: DashboardStateModel): Page | undefined {
        return state.myCompletedIncidentsPage;
    }

    @Selector()
    public static myCompletedIncidentsError(state: DashboardStateModel): DashboardError | undefined {
        return state.myCompletedIncidentsError;
    }

    constructor(private readonly dashboardApi: DashboardApiService) {}

    @Action(DashboardActions.GetMyActiveIncidents, { cancelUncompleted: true })
    public getMyActiveIncidents(context: StateContext<DashboardStateModel>, { pageEvent }: DashboardActions.GetMyActiveIncidents) {
        context.patchState({ isProcessing: true, myActiveIncidentsError: undefined });

        return this.dashboardApi.getMyActiveIncidents(pageEvent).pipe(
            tap(({ content, page }) => context.patchState({ myActiveIncidents: content, myActiveIncidentsPage: page })),
            catchError((error) => {
                context.patchState({ myActiveIncidentsError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(DashboardActions.GetMyPlannedIncidents, { cancelUncompleted: true })
    public getMyPlannedIncidents(context: StateContext<DashboardStateModel>, { pageEvent }: DashboardActions.GetMyPlannedIncidents) {
        context.patchState({ isProcessing: true, myPlannedIncidentsError: undefined });

        return this.dashboardApi.getMyPlannedIncidents(pageEvent).pipe(
            tap(({ content, page }) => context.patchState({ myPlannedIncidents: content, myPlannedIncidentsPage: page })),
            catchError((error) => {
                context.patchState({ myPlannedIncidentsError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }

    @Action(DashboardActions.GetMyCompletedIncidents, { cancelUncompleted: true })
    public getMyCompletedIncidents(context: StateContext<DashboardStateModel>, { pageEvent }: DashboardActions.GetMyCompletedIncidents) {
        context.patchState({ isProcessing: true, myCompletedIncidentsError: undefined });

        return this.dashboardApi.getMyCompletedIncidents(pageEvent).pipe(
            tap(({ content, page }) => context.patchState({ myCompletedIncidents: content, myCompletedIncidentsPage: page })),
            catchError((error) => {
                context.patchState({ myCompletedIncidentsError: error });

                return EMPTY;
            }),
            finalize(() => context.patchState({ isProcessing: false }))
        );
    }
}
